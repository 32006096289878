@use "assets/styles" as *;

div.change-phone-number {
  padding: rem(20);
  min-height: rem(300);
  height: 100%;
  position: relative;

  p.change-notice {
    font-size: rem(14);
    font-weight: bold;
    text-align: left;
    padding: rem(12);
  }

  div.sheetz-input {
    margin-bottom: rem(40);
  }

  div.buttons {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: rem(16);

    button {
      display: block;
      margin: auto;

      &.submit-button {
        width: calc(100% - #{rem(40)});
        margin-bottom: rem(16);
      }
    }
  }
}
