@use "assets/styles" as *;

div.information-footer-container {
  padding: rem(20);
  background-color: $white;
  margin-top: rem(40);

  p.heading {
    @include reg-body;
    color: $secondary-medium-gray;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: $desktop-width;

    li {
      border-bottom: 1px solid $light-gray;
      padding-bottom: rem(8);
      margin-top: rem(8);

      &:last-of-type {
        border-bottom: none;
      }

      button.footer-button {
        @include lg-body;
        color: $primary-dark-gray;
        padding: 0 rem(10);
      }
    }
  }

  &.dark-mode {
    background-color: $primary-dark-gray;

    p.heading {
      color: $light-gray;
    }

    ul {
      li {
        border-bottom: 1px solid $secondary-medium-gray;

        button.footer-button {
          color: $white;
        }
      }
    }
  }

  &.light-mode {
    background-color: $off-white;
  }
}