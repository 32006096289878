@use "assets/styles" as *;

span.vehicle-icon,
span.vehicle-icon-small {
  display: flex;
  align-items: center;
  svg {
    height: rem(60);
    border-radius: rem(15);
    padding: rem(4);

    path {
      fill: $white;
    }
  }

  svg.blackIcon {
    background-color: $vehicle-black;
  }
  svg.blueIcon {
    background-color: $vehicle-blue;
  }
  svg.grayIcon {
    background-color: $vehicle-gray;
  }
  svg.greenIcon {
    background-color: $vehicle-green;
  }
  svg.maroonIcon {
    background-color: $vehicle-maroon;
  }
  svg.navyIcon {
    background-color: $vehicle-navy;
  }
  svg.orangeIcon {
    background-color: $vehicle-orange;
  }
  svg.otherIcon {
    background-color: $vehicle-other;
  }
  svg.purpleIcon {
    background-color: $vehicle-purple;
  }
  svg.redIcon {
    background-color: $vehicle-red;
  }
  svg.yellowIcon {
    background-color: $vehicle-yellow;
  }
  svg.silverIcon {
    background-color: $vehicle-silver;
  }
  svg.tanIcon {
    background-color: $vehicle-tan;
  }
  svg.whiteIcon {
    background-color: $white;

    path {
      fill: $tertiary-gray;
    }
  }

  &.force-gray svg path {
    fill: $tertiary-gray;
  }
}

span.vehicle-icon-small {
  svg {
    height: rem(40);
    border-radius: rem(12);
  }
}
