@use "assets/styles" as *;

div.twenty-one-plus-container {
  position: relative;
  background-color: $white;
  padding: rem(40) 0 0;

  p.offer-text {
    @include reg-body;
    color: $primary-dark-gray;
    padding: 0 rem(20);
    margin-bottom: rem(40);
  }

  div.toggle {
    margin-right: rem(12);
  }
}

