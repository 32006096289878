@use "assets/styles" as *;

div.account-settings-container {
  height: 100%;

  @include desktop {
    display: grid;
    grid-template-columns: rem(250) auto;
    height: calc(100vh - #{$header-height});
  }

  div.tab-bar {
    height: rem(65);
    background-color: $sheetz-red;
    position: relative;

    @include desktop {
      height: 100%;
      border-right: rem(10) solid $page-divider !important;
      background-color: $white;
    }

    div.tab-list {
      display: flex;
      background-color: $sheetz-red;
      padding-top: rem(10);

      @include desktop {
        background-color: $white;
        padding-top: 30vh;
        display: block;
      }

      button {
        flex-grow: 1;
        border: none !important;
        outline: none !important;
        color: $white;
        background-color: $sheetz-red;
        height: rem(40);
        margin: 0 rem(15);
        font-weight: bold;
        border-bottom: rem(3) solid $sheetz-red !important;

        @include desktop {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: $primary-dark-gray;
          width: 100%;
          height: rem(60);
          margin: 0;
          padding-right: rem(20);
          border-bottom: rem(1) solid $page-divider !important;
          background-color: $white;

          &:first-child {
            border-top: rem(1) solid $page-divider !important;
          }
        }

        &.active-tab {
          border-bottom: rem(3) solid $white !important;

          @include desktop {
            border-right: rem(3) solid red !important;
            border-bottom: rem(1) solid $page-divider !important;
            border-top: none;
          }
        }

        div.account-settings-tab-label {
          display: flex;
          justify-content: center;
          align-items: center;

          @include desktop {
            width: rem(125);
            justify-content: flex-start;
          }

          svg.account-settings-tab-icon {
            height: rem(18);
            margin-right: rem(8);

            @include desktop {
              margin-right: rem(18);

              path,
              circle {
                fill: $primary-dark-gray;

                &.lock-circle-icon-svg {
                  fill: $white;
                  stroke: $primary-dark-gray;
                }
              }

            }
          }
        }
      }
    }
  }

  div.tab-content {
    overflow: hidden auto;

    &.bottom-padding {
      padding-bottom: rem(100);
    }

    @include mobile {
      height: calc(100% - #{$tab-height});
    }

    @include desktop {
      height: 100%;
    }

    button.sheetz-container-button.right-button {
      text-align: right;
      padding-right: rem(20);
    }
  }

  div.padding-for-mobile-devices {
    height: rem(200);
  }
}
