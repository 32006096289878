@use "assets/styles" as *;

div.item-customization-error-display {
  height: 100%;

  button.go-back-button {
    margin-top: rem(32);
    width: 100%;
  }
}
