@use "assets/styles" as *;

div.password-checklist {
  text-align: left;
  margin: 0 rem(10) rem(10);
  div.password-criteria {
    display: flex;
    font-size: rem(12);
    line-height: rem(20);
  }
  span.checklist-indicator {
    width: rem(16);
    height: rem(16);
    margin: 0 rem(10) 0 0;
    .indicator-circle,
    .indicator-icon {
      position: relative;
    }
    .indicator-circle {
      position: relative;
      left: 0;
      width: rem(16);
      circle {
        fill: $border;
      }
      &.success {
        circle {
          fill: $sheetz-green;
        }
      }
      &.fail {
        circle {
          fill: $error-orange;
        }
      }
    }
    .indicator-icon {
      position: relative;
      top: rem(-22);
      width: rem(12);
      margin: rem(2);
      path {
        fill: $white;
      }
    }
  }
}
