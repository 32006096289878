@use "assets/styles" as *;

div.get-rewardz-container {
  div.spendable-pointz {
    margin-top: rem(15);
    margin-left: rem(15);
    padding: rem(20) 0;
    @include obviously-narrow;
    font-size: rem(24);
  }
  div.rewardz-tier-container {
    margin-bottom: rem(10);
    display: grid;
    column-gap: rem(10);
    row-gap: rem(10);
    padding: rem(10);
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, minmax(rem(177), 1fr));
    grid-template-rows: repeat(auto-fill, minmax(rem(270), 1fr));
    max-width: rem(700);
    div.get-rewardz-item {
      position: relative;
      border: $default-border;
      border-radius: rem(10);
      padding-bottom: rem(70);

      img.rewardz-image {
        max-height: rem(120);
        max-width: 85%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: rem(5);
      }
      div.rewardz-info {
        text-align: center;
        padding: rem(12) rem(10) 0 rem(10);
        div.rewardz-name {
          font-size: rem(16);
          line-height: rem(20);
          font-weight: bold;
          word-wrap: break-word;
          margin-bottom: rem(6);
        }
        div.rewardz-detail {
          font-size: rem(14);
          line-height: rem(20);
          color: $secondary-medium-gray;
        }
      }
      div.rewardz-button-container {
        width: 100%;
        position: absolute;
        bottom: rem(10);
        padding: 0 rem(10);
        button.add-reward-button {
          width: 100%;
          min-width: rem(75);
          svg {
            transform: scaleX(-1);
            path {
              fill: $white;
            }
          }
          svg.lock-icon {
            path.lock_icon_1 {
              fill: $white;
            }
            circle.lock_icon_1 {
              fill: $white;
            }
          }
        }
      }
    }
  }

  div.level-label {
    &.hide {
      display: none;
    }
    padding: rem(10);
    display: flex;
    //@include mobile() {
    //  justify-content: center;
    //}
    color: $tertiary-gray;
    font-size: rem(16);
    span.level-icon {
      padding-right: rem(10);
      svg {
        height: rem(60);

        path.freak_icon_1 {
          fill: $sheetz-red;
        }
        path.freak_icon_2 {
          fill: $white;
        }
      }
      path.friend_icon_1 {
        fill: $sheetz-red;
      }
      path.friend_icon-2 {
        fill: $white;
      }
    }
    div.level-label-text {
      margin: auto 0;
    }
  }
}

div.action-sheet-rewardz-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto rem(58);
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  div.action-sheet-rewardz {
    display: flex;
    flex-direction: row;
    img.action-sheet-rewardz-image {
      padding: rem(10);
      width: 50%;
    }

    div.action-sheet-rewardz-info {
      text-align: center;
      margin: auto 0;
      width: 50%;
      div.action-sheet-rewardz-name {
        font-size: rem(18);
        font-weight: bold;
      }

      div.action-sheet-rewardz-detail {
        padding-top: rem(4);
        font-size: rem(14);
        color: $tertiary-gray;
      }
    }
  }
  div.action-sheet-btn-wrapper {
    padding-top: rem(10);

    button {
      width: 50%;
    }
  }
}
