@use "assets/styles" as *;

p.curbside-info-instructions {
  text-align: left;
  font-size: rem(14);
  font-weight: bold;
  margin: rem(16) rem(24) 0;
}

ul.my-curbside-info {
  list-style-type: none;
  margin: 0 0 rem(24) 0;
  padding: 0;

  li div.vehicle-flex-container,
  li div.phone-flex-container {
    display: flex;
    align-items: center;
    font-size: rem(12);
    font-weight: bold;
    line-height: rem(20);
    height: rem(45);
    padding-right: rem(24);

    p.change-vehicle,
    p.change-phone-number {
      font-size: rem(11);
      font-weight: normal;
    }
  }

  li.user-vehicle div.vehicle-flex-container {
    div.vehicle-icon-container .curbside-vehicle-icon {
      width: rem(45);
      height: rem(45);
      margin: auto;
      display: block;

      svg {
        width: 100%;
        height: 100%;
        border-radius: rem(10);
      }
    }

    p.vehicle-description {
      flex: 1;
      text-align: left;
      padding-left: 4%;

      span.select-curbside-vehicle {
        font-size: rem(16);
        color: $error-orange;
        font-weight: bold;
        margin-left: rem(-12);
      }
    }
  }

  li.phone-number div.phone-flex-container {
    justify-content: space-between;

    span.add-phone-number {
      font-size: rem(16);
      color: $error-orange;
      font-weight: bold;
    }
  }
}

button.curbside-info-close-button {
  width: calc(100% - #{rem(48)});
  margin-bottom: rem(24);
}

div.padding-for-mobile {
  height: rem(200);
}
