@use "assets/styles" as *;

div.selector-header {
  display: flex;
  color: $secondary-medium-gray;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;

  p.selector-title {
    font-size: rem(16);
  }

  p.selector-instructions {
    font-size: rem(10);

    &.required {
      color: $error-orange;
    }
  }
}
