@use "assets/styles" as *;

div.error-actions-sheet {
  padding: 0 rem(20);
  text-align: left;

  h1 {
    @include xxl-body;
  }

  p {
    @include lg-body;
    margin-bottom: rem(20);
  }

  div.button-container {
    padding-bottom: rem(16);

    button {
      width: 100%;

      &.email-button {
        margin-top: rem(16);
      }
    }
  }

  button.contact-us-button {
    @include lg-body;
    text-decoration: underline;
    padding: 0;
  }
}