@use "assets/styles" as *;

progress.progress-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Get rid of default border in Firefox. */
  border: none;

  width: 100%;
  height: rem(5);
  margin-bottom: rem(8);
  border-radius: rem(2);
  color: $white;
  background-color: rgba($color: #000000, $alpha: 0.2);

  &::-webkit-progress-bar {
    background-color: rgba($color: #000000, $alpha: 0.05);
    border-radius: rem(2);
  }

  &::-webkit-progress-value {
    background-color: $white;
    border-radius: rem(2);
  }

  &::-moz-progress-bar {
    background-color: $white;
    border-radius: rem(2);
  }
}
