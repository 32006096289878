@use "assets/styles" as *;

button.save-store-button {
  width: 100%;

  div.circle {
    height: rem(24);
    width: rem(24);
    background-color: $sheetz-blue;
    border: $sheetz-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    svg {
      width: 60%;
      // Pull the icon up slighty - this seems to center it better in the circle.
      margin-top: rem(-1);

      &.favorite path {
        fill: $white;
      }

      path {
        fill: $border;
      }
    }
  }
}
