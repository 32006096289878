@use "assets/styles" as *;

div.pin-entry-container {
  display: flex;

  div.pin-entry {
    width: 25%;
    min-width: rem(75);
    padding-left: rem(5);
    padding-right: rem(5);

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    div.sheetz-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: $default-border;
      width: 100%;
      height: rem(75);
      @include desktop {
        height: rem(100);
      }
      padding: 0;
      border-radius: rem(15);

      input {
        border-bottom: none;
        color: $primary-dark-gray;
        font-size: rem(40);
        font-weight: bold;
        text-align: center;
        padding: 0;
      }
    }

    .pin-entry-errors {
      border: rem(1) solid $error-orange;
    }
  }
}
