@use "assets/styles" as *;

div.continue-ordering-button {
  display: flex;
  margin: rem(44) auto rem(32);
  padding: 0 rem(20);

  button {
    width: 100%;
  }
}

div.my-order-container {
  margin-bottom: rem(32);

  p.my-order-header {
    color: $secondary-medium-gray;
    font-size: rem(14);
    padding: 0 rem(20) rem(12);
  }
}
