@use "assets/styles" as *;

.fader {
  &.hide {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    .loadingIconContainer {
      transform: translateY(-600%);
      transition: transform 0.3s ease-in-out;
    }
  }
  &.show {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    .loadingIconContainer {
      transform: translateY(0);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: $loading-overlay-index;
  background-color: rgba(0, 0, 0, 0.52);
}

.loading-container {
  z-index: $loading-container-index;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .loading-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(140);
    max-width: rem(140);
    max-height: rem(140);
    margin: rem(40);
  }
}
