@use "assets/styles" as *;

div.store-details-wrapper {
  height: 100vh;
  margin-top: $header-height;

  div.map-container,
  div.map-overlay {
    position: fixed;
    top: $header-height;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $map-container-index;
    height: 100%;
    width: 100%;
  }

  div.map-overlay {
    background-color: rgba(255, 255, 255, 0.5);
    z-index: $map-overlay-index;
  }

  div.store-details {
    max-width: rem(600);
    margin: 0 auto;
    position: relative;
    z-index: $store-details-index;
    box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.8), 0 0 8px -4px rgba(0, 0, 0, 0.8);
  }
}

