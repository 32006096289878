@use "assets/styles" as *;

div.inline-sub-selector {
  background-color: $sheetz-red;
  border-radius: rem(20);
  color: $white;
  padding: rem(10) rem(20);
  grid-column: 1 / -1;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  div.arrow-up {
    position: absolute;
    top: rem(-6);
    left: calc(50% - #{rem(6)});
    width: 0;
    height: 0;
    border-left: rem(6) solid transparent;
    border-right: rem(6) solid transparent;
    border-bottom: rem(6) solid $sheetz-red;
  }

  p.display-text {
    @include obviously-narrow;
    font-size: rem(18);

    svg.checkmark-icon {
      height: rem(18);
      vertical-align: top;
      margin-right: rem(10);

      path {
        fill: $white;
      }
    }
  }

  p.price {
    font-size: rem(10);
  }
}

ul.inline-options-list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 rem(16) 0;

  li {
    margin-top: rem(16);
    button.inline-sub-option-button {
      width: 100%;
    }
  }
}
