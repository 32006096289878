@use "assets/styles" as *;

div.reload-container {
  padding: rem(20);
  .choose-payment-method-label {
    color: $tertiary-gray;
    font-size: rem(16);
    font-weight: bold;
    padding-top: rem(10);
  }
  @include desktop {
    max-width: rem(600);
    margin: auto;
  }
  .payment-method-selected {
    border: rem(2) solid;
    border-color: $pay-green;
    border-radius: rem(15);
  }
  .next-button {
    padding-top: rem(20);
    margin-bottom: rem(40);
  }
  .add-payment-method-button {
    padding-bottom: rem(70);
    padding-top: rem(30);
    border-bottom: 1px solid $border;
    border-top: $default-border;
  }
  .button-row {
    height: rem(48);
    display: flex;
    align-items: center;

    button {
      flex-grow: 1;
      margin-top: rem(40);
    }
  }
  .payment-method-item {
    padding: rem(10) 0;
    margin: rem(10) 0;
    button {
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      padding: rem(10);
      div {
        display: grid;
        grid-template-columns: rem(50) auto rem(60);
        margin: auto;
        div {
          display: block;
        }
        .payment-method-icon-container svg {
          width: rem(32);
          height: rem(32);
        }
        .payment-method-label-container {
          margin: auto rem(12);
          button.reload-change-payment-type {
            padding: rem(6) 0;
            height: initial;
            font-size: rem(12);
            color: $sheetz-blue;
            text-decoration: none;
            font-weight: normal;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            line-height: rem(16);
            margin: rem(8) auto rem(4);
            &.payment-method-name {
              font-weight: bold;
              font-size: rem(16);
              color: $primary-dark-gray;
            }
            &.payment-method-number {
              color: $tertiary-gray;
            }
          }
        }
        .payment-method-default-container {
          font-size: rem(12);
          color: $tertiary-gray;
          .refresh-icon {
            width: rem(32);
            path {
              fill: $primary-dark-gray;
            }
          }
        }
      }
    }
    &.placeholder button div {
      .payment-method-icon-container {
        background-color: $border;
        width: rem(50);
        height: rem(50);
        margin: auto;
        border-radius: rem(10);
      }
      .payment-method-label-container p {
        height: rem(16);
        background-color: $border;
        border-radius: rem(4);
      }
    }
  }
  .add-payment-method-row {
    width: 100%;
    text-align: center;
    button.add-payment-method-button {
      margin: rem(30) auto 0;
      width: 90%;
      max-width: rem(400);
    }
  }
}

.confirm-gift-card-reload-container {
  height: calc(100% - #{$header-height});
  .confirm-gift-card-reload-container-header {
    .header-row {
      display: block;
      padding-top: rem(30);
    }
    background-color: $pay-green;
    color: $white;

    .amount-header {
      font-size: rem(60);
      padding-bottom: rem(30);
    }
  }
  .purchase-details-container {
    // This calculation accounts for the footer height, header and then the additional header section that was added for this modal.
    height: calc(100% - #{$payment-footer-height} - #{$header-height} * 1.5);
    overflow: scroll;
    .purchase-details-label {
      padding-top: rem(20);
      padding-left: rem(30);
      text-align: left;
      color: $tertiary-gray;
      font-size: rem(16);
      font-weight: bold;
    }
    .purchase-details-row {
      @include desktop {
        padding-top: rem(30);
      }
      padding-top: rem(10);
      display: flex;
      color: $primary-dark-gray;
      font-weight: bold;
      font-size: rem(20);
      .reload-amount-label {
        margin-left: rem(50);
        padding-left: rem(5);
        padding-bottom: rem(20);
        text-align: left;
        flex: 1;
        border-bottom: $default-border;
      }
      .balance-below-label {
        flex: 2;
      }
      .reload-amount {
        flex: 1;
        text-align: right;
        margin-right: rem(50);
        padding-bottom: rem(20);
        padding-right: rem(5);
        border-bottom: $default-border;
      }
      .reload-enabled-label {
        color: $pay-green;
      }
    }
  }

  .confirm-gift-card-reload-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
