@use "assets/styles" as *;

div.mid-container {
  background-color: $sheetz-red;
  padding: rem(30) rem(20);
  color: $white;

  div.verify-your-age-header {
    @include lg-title;
    margin: rem(12) 0;
  }

  div.you-must-be-label {
    @include lg-body;
    font-weight: bold;
    margin: rem(12) 0;
  }

  div.we-will-verify {
    @include sm-body;
    margin-top: rem(12);
  }

  div.step-indicators {
    display: flex;

    div.step-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: rem(30);
      height: rem(30);
      min-width: rem(30);
      font-weight: bold;
      font-size: rem(15);
      background-color: $white;
      color: $sheetz-red;
      margin-right: rem(8);

      &.completed {
        color: $sheetz-red;
      }

      &.next {
        background-color: $sheetz-red;
        color: $white;
        border: rem(2) solid $white;
      }
    }
  }
}
