@use "assets/styles" as *;

div.loyalty-points-earned {
  display: flex;
  background-color: $sheetz-red;
  color: $white;
  padding: rem(20);
  border-radius: rem(15);
  align-items: center;
  gap: rem(16);

  span.points {
    @include lg-title;
    // Slight vertical adjustment required due to font line-height variance
    position: relative;
    top: rem(-3);
  }

  span.points-label {
    @include lg-body;
  }
}
