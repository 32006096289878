@use "assets/styles" as *;

div.delivery-provider-container {
  display: flex;
  align-items: center;
  @include sm-body;
  color: $secondary-medium-gray;
  margin: rem(8) 0;

  svg {
    margin-left: rem(6);
    width: rem(100);
  }
}
