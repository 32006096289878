@use "assets/styles" as *;

div.change-password-container {
  position: relative;
  background-color: $white;

  div.change-password-form {
    max-width: rem(600);
    padding: rem(50) rem(16) rem(20);

    .row {
      margin-bottom: rem(10);
    }
  }
}
