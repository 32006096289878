@use "assets/styles" as *;

div.add-payment-method-container {
  .message {
    font-size: rem(12);
    color: $tertiary-gray;
    padding: rem(20) rem(20) 0;
    margin: 0 auto;
    max-width: rem(600);

    @include desktop {
      padding-left: 0;
    }
  }
  .payment-method-item {
    button {
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      padding: rem(10);
      margin: 0 auto;
      max-width: rem(600);
      div {
        display: grid;
        grid-template-columns: rem(50) auto rem(60);
        margin: auto;
        div {
          display: block;
        }
        .payment-method-icon-container svg {
          width: rem(32);
          height: rem(32);
        }
        .payment-method-label-container {
          margin: auto rem(12);
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            line-height: rem(16);
            margin: rem(8) auto rem(4);
            &.payment-method-name {
              font-weight: bold;
              font-size: rem(16);
              color: $primary-dark-gray;
            }
            &.payment-method-number {
              color: $tertiary-gray;
            }
          }
        }
        .payment-method-default-container {
          font-size: rem(12);
          color: $tertiary-gray;
          .refresh-icon {
            width: rem(32);
            path {
              fill: $primary-dark-gray;
            }
          }
        }
      }
    }
    &.placeholder button div {
      .payment-method-icon-container {
        background-color: $border;
        width: rem(50);
        height: rem(50);
        margin: auto;
        border-radius: rem(10);
      }
      .payment-method-label-container p {
        height: rem(16);
        background-color: $border;
        border-radius: rem(4);
      }
    }
  }
  .add-payment-method-row {
    width: 100%;
    text-align: center;

    button.add-payment-method-button {
      margin: rem(30) auto 0;
      width: 90%;
      max-width: rem(400);

      @include desktop {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
