@use "assets/styles" as *;

div.button-footer-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $button-footer-container-index;
  padding: rem(20) rem(24);
  align-items: center;
  background-color: $primary-dark-gray;
  display: flex;
  flex-direction: column;

  &.in-mobile-app {
    padding-bottom: rem(45);
  }

  button {
    width: 100%;
    max-width: rem(320);
  }
}
