@use "assets/styles" as *;

p.payment-exp-balance {
  @include sm-body;
  margin-top: rem(-4);
  margin-left: rem(12);

  &.low-funds-expired {
    color: $error-orange;
  }
}