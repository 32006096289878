@use "assets/styles" as *;

div.order-main-content.in-mobile-app div.order-history-detail-container {
  height: 100vh;
}

div.order-history-detail-container {
  height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;

  div.order-history-detail-scroll-content {
    flex-grow: 1;
    padding: rem(20) 0;
    overflow-y: auto;

    @include desktop-max-width {
      width: 100%;
      overflow-y: initial;
    }

    div.order-info {
      line-height: rem(20);
      font-weight: 500;
      padding: 0 rem(20);

      p.canceled-order-notice {
        color: $sheetz-red;
        margin-bottom: rem(8);
      }
      p.pending-delivery-order-notice {
        color: $secondary-medium-gray;
        margin-bottom: rem(8);
      }

      div.order-number {
        font-size: rem(18);
      }

      div.order-date,
      div.order-time {
        font-size: rem(14);
      }
    }
    ul.order-item-list {
      list-style-type: none;
      padding-left: 0;
    }
    div.subtotal-row {
      font-size: rem(14);
      padding: 0 rem(20);
      margin-bottom: rem(32);
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    div.order-action-button-container {
      text-align: center;

      button.order-receipt-button,
      button.order-cancel-button {
        width: 100%;
        max-width: rem(320);
      }

      button.order-cancel-button {
        margin-top: rem(8);
      }
    }
  }
  div.order-history-detail-footer {
    padding: rem(20) rem(20) rem(10) rem(20);
    align-items: center;
    background-color: $primary-dark-gray;
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      max-width: rem(320);
      margin-bottom: rem(10);

      &.add-to-favorites-button.favorited {
        svg {
          transform: scale(1.75);

          path {
            fill: $white;
          }
        }
      }
    }
  }
}

div.in-mobile-app div.order-history-detail-container {
  div.order-history-detail-footer {
    padding-bottom: rem(42);
  }
}
