@use "assets/styles" as *;

div.auto-reload-container {
  .flex-row {
    display: flex;
  }

  .choose-reload-amount-label {
    text-align: left;
    padding-top: rem(15);
    padding-bottom: rem(10);
  }

  .auto-reload-border {
    border-bottom: $default-border;
  }

  .reload-when-balance-label {
    text-align: left;
    padding-top: rem(25);
    padding-bottom: rem(10);
  }

  .button-row {
    display: flex;
    margin-top: rem(50);
    align-items: center;

    button {
      flex: 1;
    }
  }
}
