@use "assets/styles" as *;
a.menu-item-link,
div.menu-item-button {
  // TODO style this better
  &:focus {
    border-color: $primary-dark-gray;
    background-color: $border;
  }

  &.favorite,
  &.order {
    border-bottom: $default-border;
  }

  text-decoration: inherit;
  color: inherit;
  display: block;
  width: 100%;

  div.menu-item-flex-container {
    display: flex;

    div.menu-item {
      display: flex;
      align-items: center;
      min-height: rem(65);

      > {
        margin: auto;
      }

      span.sheetz-red {
        color: $sheetz-red;
      }
      span.secondary-text {
        color: $secondary-medium-gray;
      }

      div.item-image {
        min-width: rem(80);

        svg.menu-item-placeholder-image {
          width: 100%;
          max-width: 100%;
          max-height: rem(65);
        }

        svg path {
          fill: $tertiary-gray;
        }
        svg.menu-item-placeholder-image-sheetz-go {
          max-width: 100%;
          max-height: rem(65);

          path {
            fill: $sheetz-blue;
          }
          .cls-1 {
            fill: $sheetz-blue;
          }
        }
      }

      p.menu-item-order-status {
        padding-bottom: rem(4);
        font-size: rem(12);
      }

      div.menu-item-info-container {
        text-align: right;
        padding-right: rem(12);

        p.price {
          font-size: rem(11);
          color: $secondary-medium-gray;
        }
        p.pickup-date {
          font-size: rem(11);
          color: $secondary-medium-gray;
        }
        p.calories {
          font-size: rem(10);
          color: $tertiary-gray;
        }
      }
    }

    button.favorite-edit-button {
      width: rem(64);
      text-align: center;
      svg {
        max-height: rem(16);
        path,
        rect {
          fill: $tertiary-gray;
        }
      }
    }
  }

  div.item-unavailable-button-container {
    margin: 0 rem(20) rem(16);

    button.unavailable-notice {
      width: 100%;
    }
  }
}
