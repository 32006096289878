@use "assets/styles" as *;

div.cancel-order-button-container {
  text-align: center;
  margin-top: rem(5);

  button.order-cancel-button {
    width: 60%;
    max-width: rem(320);
  }

  p {
    margin-top: rem(16);
  }
}
