@use "assets/styles" as *;

div.delivery-instructions-container {
  div.delivery-instructions-info {
    font-weight: bold;
    display: flex;
    div.instructions-container {
      width: 85%;
      display: flex;
      flex-direction: column;
      line-height: 150%;
      div.delivery-instructions-header {
        font-weight: bold;
      }
      div.delivery-instructions-label {
        font-size: rem(12);
        color: $secondary-medium-gray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    div.delivery-instructions-icon {
      margin-left: auto;
      button.delivery-dots-btn {
        @include unstyled-button();
        font-size: rem(12);
        width: rem(24);
        height: rem(24);
        border-radius: rem(12);
        text-align: center;
        margin: rem(10) rem(2);
        color: $white;
        background-color: $white;
        svg {
          height: rem(15);
        }
      }
    }
  }
}
