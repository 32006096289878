@use "assets/styles" as *;

div.store-display {
  ul.store-unavailable-alerts {
    @include reg-body;
    background-color: $error-orange;
    padding: rem(10);
    border-radius: rem(10);
    color: $white;
    margin-bottom: rem(16);
    list-style-type: disc;

    li {
      margin-left: rem(12);

      &:not(:first-child) {
        margin-top: rem(-5);
      }
    }
  }

  div.store-display-flex-container {
    display: flex;
    align-items: flex-start;
    gap: rem(10);

    div.store-address-container {
      flex: 1;

      div.address {
        @include lg-body;
        color: $primary-dark-gray;

        p.street {
          margin-bottom: rem(-4);
        }

        p.mileage {
          @include sm-body;
          margin-top: rem(20);
          color: $secondary-medium-gray;
        }

        svg {
          width: rem(20);
          margin: rem(0) rem(5) rem(-2) rem(0);
          color: $secondary-medium-gray;
        }
      }

      p.store-number {
        @include sm-body;
        color: $primary-dark-gray;
      }

      p.directions {
        @include lg-body;
        color: $primary-dark-gray;
      }
    }

    div.store-icon-container {
      div.circle {
        height: rem(30);
        width: rem(30);
        background-color: $sheetz-blue;
        border: $sheetz-blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(6);

        svg {
          width: 60%;
          margin-top: rem(-1);

          &.favorite path {
            fill: $white;
          }

          path {
            fill: $border;
          }
        }
      }

      .wine-icon {
        width: rem(30);
        margin-top: rem(6);
      }
    }
  }

  div.favorite-store-button-container {
    margin-top: rem(20);
    max-width: rem(350);
  }
}
