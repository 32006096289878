@use "assets/styles" as *;

.loading-placeholder {
  background-color: #fff;
  padding: rem(12);
  margin: 0 auto;
  max-width: rem(472);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: rem(-468) 0;
  }
  100% {
    background-position: rem(468) 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: rem(800) rem(104);
  height: rem(96);
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: rem(40);
  right: 0;
  height: rem(10);
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: rem(10);
  left: rem(40);
  height: rem(8);
  width: rem(10);
}

.background-masker.header-bottom {
  top: rem(18);
  height: rem(6);
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: rem(24);
  height: rem(6);
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: rem(300);
  right: 0;
}

.background-masker.subheader-right {
  left: rem(230);
}

.background-masker.subheader-bottom {
  top: rem(30);
  height: rem(10);
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: rem(40);
  left: 0;
  right: 0;
  height: rem(6);
}

.background-masker.content-top {
  height: rem(20);
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: rem(380);
  right: 0;
  top: rem(60);
  height: rem(8);
}

.background-masker.content-second-line {
  top: rem(68);
}

.background-masker.content-second-end {
  left: rem(420);
  top: rem(74);
}

.background-masker.content-third-line {
  top: rem(82);
}

.background-masker.content-third-end {
  left: rem(300);
  top: rem(88);
}
