@use "assets/styles" as *;

$result-container-height: rem(100);

.search-container {
  // Making space for the header
  height: calc(100% - #{$header-height});
  .search-input {
    padding: 0 rem(20);
  }

  .search-results-selector {
    display: flex;
    flex-grow: 1;
    button {
      @include unstyled-button;
      margin: rem(1) rem(6);
      flex-basis: 50%;
      color: $secondary-medium-gray;
      font-weight: bold;
      font-size: rem(14);
      height: rem(60);
      border-bottom: rem(1) solid $page-divider;
      &.active {
        border-bottom: rem(2) solid $sheetz-red;
      }
    }
  }
  .search-results-container {
    padding: rem(5);
    // rem(75) spacing for the search bar rem(25) for the rounded bottom
    height: calc(100% - #{$result-container-height});
    overflow-y: auto;
    list-style-type: none;
    margin: 0;
    ul.search-results-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li div.list-item-container {
        padding: rem(10) rem(15);
      }
    }

    p.result-count {
      padding: rem(11) rem(20) rem(16);
      font-size: rem(14);
      border-bottom: $default-border;
    }

    &.no-results {
      padding-top: rem(60);
      text-align: center;

      > * {
        margin: rem(10);
      }

      p {
        font-size: rem(12);

        &.no-results-text {
          font-size: rem(16);
          font-weight: bold;
        }

        color: $secondary-medium-gray;
      }

      svg {
        width: rem(32);

        path {
          fill: $tertiary-gray;
        }
      }
    }
  }
}
