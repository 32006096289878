@use "assets/styles" as *;

div.my-rewardz-label {
  padding: rem(20) rem(20) rem(10) rem(20);
  color: $secondary-medium-gray;
}

div.my-rewardz-container {
  margin-bottom: rem(10);
  display: grid;
  column-gap: rem(10);
  row-gap: rem(10);
  padding: rem(10);
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(rem(145), 1fr));
  grid-template-rows: repeat(auto-fill, minmax(rem(270), 1fr));
  max-width: rem(700);

  div.my-rewardz-item {
    position: relative;
    border: $default-border;
    border-radius: rem(10);
    padding-bottom: rem(70);

    img.my-rewardz-image {
      max-height: rem(120);
      max-width: 85%;

      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: rem(5);
    }

    div.my-rewardz-info {
      text-align: center;
      padding: rem(12) rem(10) 0 rem(10);

      div.my-rewardz-name {
        font-size: rem(16);
        line-height: rem(20);
        font-weight: bold;
        word-wrap: break-word;
      }

      div.my-rewardz-detail {
        font-size: rem(14);
        line-height: rem(20);
        color: $secondary-medium-gray;
      }
    }

    div.my-rewardz-amount-date {
      display: grid;
      grid-template-columns: 50px auto;
      padding: 0 rem(12);
      width: 100%;
      align-items: center;
      position: absolute;
      bottom: rem(10);

      div.my-rewardz-count {
        border-radius: rem(48);
        height: rem(48);
        width: rem(48);
        min-width: rem(48);
        font-weight: bold;
        font-size: rem(16);
        line-height: rem(50);
        background-color: $sheetz-green;
        color: $white;
        margin: auto;
        text-align: center;
      }

      div.my-rewardz-expiration {
        padding-left: rem(20);
        padding-right: rem(15);
        font-size: rem(12);
        color: $tertiary-gray;
        text-align: right;
      }
    }
  }
}

div.action-sheet-rewardz-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto rem(58);
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  div.action-sheet-rewardz {
    display: flex;
    flex-direction: row;

    img.action-sheet-rewardz-image {
      padding: rem(10);
      width: 50%;
    }

    div.action-sheet-rewardz-info {
      text-align: center;
      margin: auto 0;
      width: 50%;

      div.action-sheet-rewardz-name {
        font-size: rem(18);
        font-weight: bold;
      }

      div.action-sheet-rewardz-detail {
        padding-top: rem(4);
        font-size: rem(14);
        color: $tertiary-gray;
      }
    }
  }

  div.action-sheet-btn-wrapper {
    padding-top: rem(10);
    button {
      width: 50%;
    }
  }
}
