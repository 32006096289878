@use "assets/styles" as *;

div.order-information-container {
  height: 100%;

  div.order-information-inner-container {
    padding: 0 rem(30);
    width: 100%;
    max-width: rem(500);
    margin-top: -$header-height; // We subtract the header height so that the content is centered within the viewport.

    @include desktop {
      max-width: $desktop-width-rem;
    }

    p.heading {
      color: $secondary-medium-gray;
    }

    ul {
      margin: rem(20) 0 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
