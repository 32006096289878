@use "assets/styles" as *;

div.order-submitted {
  div.section-container {
    padding: rem(20);
    border-bottom: $default-border;

    &.savings,
    &.order-totals {
      // These two sections have components above them that interfere with the layout, so we remove padding.
      padding-top: 0;
    }
  }

  div.store-display-container,
  div.delivery-address-container {
    padding: rem(20);
    @include bottom-border;
  }

  div.order-details-container {
    padding-bottom: rem(192);

    div.save-order-button-flex-container {
      display: flex;
      cursor: pointer;
      align-items: center;
      margin: 0;
      margin-left: rem(-5);

      div.circle {
        height: rem(30);
        width: rem(30);
        border: $default-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: rem(16);

        svg {
          width: 60%;
          // Pull the icon up slightly - this seems to center it better in the circle.
          margin-top: rem(-1);

          &.favorite path {
            fill: $sheetz-red;
          }

          path {
            fill: $border;
          }
        }
      }

      p.label {
        font-weight: bold;
      }
    }

    div.padding-container {
      padding-top: rem(32);
    }

    div.points-earned-container {
      margin-top: rem(34);
    }

    div.cancel-order-container {
      padding: rem(30) rem(10) rem(10);
    }
  }

  div.new-order-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $primary-dark-gray;
    padding: rem(20);

    button {
      width: 100%;

      @include desktop-max-width {
        display: block;
      }
    }
  }

  p.cancel-confirm {
    margin-top: rem(32);
    font-weight: bold;
    font-size: rem(18);
    text-align: left;
    padding: 0 rem(16);
  }

  div.cancel-confirm-flex-container {
    display: flex;
    padding: rem(64) rem(16) rem(40);

    > * {
      flex: 1;
    }
  }
}
