@use "assets/styles" as *;

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modal-index;
  background-color: rgba(0, 0, 0, 0.52);
}
.sheetz-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;

  background-color: $white;
  @include desktop-modal {
    height: 80%;
    width: $desktop-modal-width;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: rem(25);
  }

  div.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(70);
    @include lg-body;
    color: $white;
    background-color: $sheetz-red;


    @include desktop-modal {
      border-radius: rem(25) rem(25) 0 0;
    }

    &.modal-header-green {
      background-color: $pay-green;
    }

    button.modal-close {
      @include mobile {
        display: none;
      }
      position: absolute;
      top: 0;
      right: 0;
      margin: rem(26) rem(10);
      background-color: transparent;
      border: none;
      float: right;

      svg.modal-close-icon {
        width: rem(16);
        path {
          fill: $white;
        }
      }
    }
  }

  div.modal-content {
    height: calc(100% - 90px);
    overflow-y: scroll;
  }

  div.modal-footer {
    height: 20px;
  }
}
