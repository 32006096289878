@use "assets/styles" as *;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.payment-footer {
  background-color: $primary-dark-gray;
  color: $white;
  padding: rem(20);
  min-height: $payment-footer-height;

  &.delivery {
    min-height: $payment-footer-delivery-height;
  }

  &.order-confirmation {
    @include order-confirmation-desktop {
      min-height: $desktop-payment-footer-height;
      background-color: transparent;
      padding: 0;

      &.delivery {
        min-height: $desktop-payment-footer-delivery-height;
      }
    }
  }

  div.payment-details-container {
    margin: 0 auto rem(8);

    @include desktop {
      max-width: $desktop-width-rem;
      padding: 0 rem(20);
    }

    button.payment-info-container {
      display: flex;
      align-items: center;
      color: $white;
      min-height: rem(42);

      &.mobile-payment {
        margin-bottom: rem(4);
      }

      img.payment-method-icon,
      svg.payment-method-icon {
        height: rem(32);
        width: rem(52);
      }

      div.payment-type-flex-container {
        display: flex;
        align-items: center;
      }

      p.payment-type {
        @include lg-body;
        margin-left: rem(12);
      }
    }
  }

  &.no-payment-methods {
    display: flex;
    justify-content: center;
    flex-direction: column;

    div.no-payment-methods-container {
      margin: 0 auto;
      width: 100%;

      @include desktop {
        max-width: $desktop-width-rem;
        padding: 0 rem(40);
      }

      p.instructions {
        @include lg-body;
        text-align: center;
        margin-bottom: rem(18 );
      }

      button {
        width: 100%;
      }
    }

  }

  &.loading-payment-methods > * {
    opacity: 0;
    animation: fadeIn ease 700ms;
    animation-delay: 700ms;
    animation-fill-mode: forwards;
  }

  &.loading-payment-methods,
  &.submitting-order {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .card-icon,
    .burger-icon {
      path {
        fill: $white;
      }
    }

    p {
      @include lg-body;
      margin-top: rem(16);
    }
  }

  &.error-loading-payments {
    display: flex;

    div.error-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 auto;
      width: 100%;
      margin: 0 auto;

      @include desktop {
        max-width: $desktop-width-rem;
        padding: 0 rem(40);
      }

      div.error-message {
        display: flex;

        svg.exclamation-circle-icon {
          width: rem(24);
          height: rem(24);
        }

        p {
          @include lg-body;
          margin-left: rem(12);
        }
      }

      button.refresh {
        margin: rem(18) auto 0;
        width: 100%;

        span.text-left {
          padding-top: rem(3);
        }

        span.text-right {
          padding-top: rem(6);
        }

        svg.refresh-icon {
          width: rem(20);
          height: rem(20);

          path {
            fill: $white;
          }
        }
      }
    }
  }

  button.continue-ordering-button {
    width: 100%;
    margin-top: rem(10);
  }
}
