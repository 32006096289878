@use "assets/styles" as *;

div.delivery-phone-number-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height});
  max-width: 500px;
  margin: 0 auto;
  padding: rem(30);

  @include desktop {
    height: calc(#{$modal-desktop-height} - #{$header-height});
  }

  form.delivery-phone-number-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  p.delivery-phone-number-instructions {
    font-weight: bold;
    line-height: 150%;
    margin-bottom: rem(25);
    text-align: left;
  }

  button.submit-phone-number {
    margin: rem(20) auto;
    display: block;
    width: 100%;
    max-width: rem(500);
  }
}
