@use "assets/styles" as *;

div.store-filters-container {
  path {
    fill: $secondary-medium-gray;
  }

  .cls-1 {
    fill: $secondary-medium-gray;
  }

  .customization-check {
    fill: $white;
  }

  div.filters-container {
    padding: rem(20);

    div.filters-label {
      text-align: left;
      font-size: rem(16);
      color: $tertiary-gray;
      font-weight: bold;
      padding: rem(10) 0 rem(15) 0;
    }

    div.filters-grid {
      margin-bottom: rem(10);
      display: grid;
      column-gap: rem(10);
      row-gap: rem(10);
      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fill, minmax(rem(90), 1fr));
      grid-template-rows: repeat(auto-fill, minmax(rem(90), 1fr));
      max-width: rem(700);
      padding-bottom: rem(20);

      p {
        padding-top: rem(10);
        text-align: center;
        width: 70%;
        margin: auto;
        line-height: rem(18);
      }

      .customization-check-icon {
        position: absolute;
        width: 15%;
        top: 15%;
        right: -7%;
      }

      .filter {
        display: flex;
        flex-direction: column;
        position: relative;
        border: $default-border;
        border-radius: rem(10);
        padding-bottom: rem(20);
        padding-top: rem(20);
        font-size: rem(12);
        font-weight: bold;
        min-height: rem(150);

        div.filter-icon {
          width: rem(50);
          margin: auto;

          svg {
            width: rem(50);
            height: auto;
          }
        }
      }

      &.ev-charger-types {
        .filter {
          div.filter-icon {
            width: rem(80);

            svg.ev-type-icon {
              width: rem(80);
              height: rem(80);
            }
          }
        }
      }

      .selected-filter {
        border: rem(1) solid $sheetz-red;
      }
    }
  }

  .lower-container {
    margin-bottom: rem(80);
  }

  .upper-container {
    border-bottom: $default-border;
  }
}

div.store-filters-footer-container {
  position: absolute;
  bottom: rem(0);
  left: 0;
  right: 0;
  height: rem(145);
  overflow: hidden;

  .footer-swoop {
    display: block;

    @include desktop {
      position: absolute;
      bottom: rem(-25);
      height: rem(170);
      right: 0;
      left: 0;
    }

    path {
      fill: $primary-dark-gray;
    }
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: $store-filter-button-row-index;
    position: absolute;
    right: 0;
    bottom: rem(15);
    left: 0;

    .clear-filters-button {
      max-width: rem(130);
    }

    .apply-filters-button {
      max-width: rem(200);
    }

    @include desktop {
      .clear-filters-button,
      .apply-filters-button {
        max-width: none;
      }

      .apply-filters-button {
        margin-left: rem(20);
      }
    }
  }
}
