@use "assets/styles" as *;

$top-offset: rem(-56);

@include mobile() {
  div.added-to-bag-container {
    &.hide {
      transform: translateY(rem(270));
      transition: transform 0.3s ease-in-out;
    }

    &.show {
      transform: translateY(0);
      transition: transform 0.3s ease-in-out;
    }

    position: fixed;
    height: rem(200);
    background-color: $primary-dark-gray;
    bottom: 0;
    width: 100%;

    &.in-mobile-app {
      height: rem(232);
    }

    svg.added-to-bag-swoop {
      position: relative;
      top: $top-offset;
      height: rem(100);
      width: 100%;
      path {
        fill: $sheetz-green;
      }
    }

    svg.added-to-bag-triangle {
      position: absolute;
      right: rem(32);
      width: rem(32);
      transform: rotate(180deg);
      path {
        fill: $sheetz-green;
      }
    }

    div.added-to-bag-label {
      display: flex;
      align-items: center;
      color: $white;
      @include obviously-narrow;
      font-size: rem(24);
      position: absolute;
      top: rem(-32);
      left: rem(48);

      svg.added-to-bag-check {
        height: rem(24);

        path {
          fill: $white;
          margin-right: rem(16);
        }
      }

      span.added-to-bag-title {
        padding-left: rem(12);
      }
    }

    div.button-container {
      position: relative;
      top: $top-offset;
      padding: 0 rem(20);

      button {
        flex: 1;
      }
    }

    &.in-mobile-app button.added-to-bag-checkout-button {
      bottom: rem(56);
    }
  }
}

@include desktop() {
  div.added-to-bag-container {
    &.hide {
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    }

    &.show {
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
    }

    position: fixed;
    height: rem(150);
    background-color: $sheetz-green;
    top: rem(90);
    width: rem(180);
    right: rem(20);
    border-radius: rem(15);
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    padding: rem(10);

    svg.added-to-bag-triangle {
      position: absolute;
      top: rem(-16);
      right: rem(32);
      width: rem(32);
      path {
        fill: $sheetz-green;
      }
    }

    div.added-to-bag-label {
      display: flex;
      align-items: center;
      color: $white;
      @include obviously-narrow;
      font-size: rem(20);

      svg.added-to-bag-check {
        height: rem(20);

        path {
          fill: $white;
          margin-right: rem(16);
        }
      }

      span.added-to-bag-title {
        padding-left: rem(12);
      }
    }

    button.added-to-bag-checkout-button {
      margin: rem(10) rem(5);
    }
    button.added-to-bag-toggle-button {
      color: $white !important;
      height: rem(32);
    }
  }
}
