@use "assets/styles" as *;

div.responsive-layout-container {
  @include desktop-max-width {
    flex: 1 0 auto;

    &.account-settings {
      padding: rem(30) rem(24);
      margin: 0;
    }
  };


}
