@use "assets/styles" as *;

.nav-menu-items {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    border-bottom: rem(1) solid $border;

    button.title-container {
      background-color: $page-divider;
    }

    > button {
      width: 100%;
      padding: rem(24) 0;
      color: $primary-dark-gray;
      font-size: rem(16);
      font-weight: bold;
      text-align: left;
      background-color: $white;
      border: none;
      line-height: 150%;

      @include desktop {
        padding: rem(24) 0;
      }

      div.question-content {
        display: flex;
        justify-content: space-between;
        padding: 0 rem(16);

        div.title {
          padding-right: rem(16);
        }

        div.arrow {
          width: rem(10);

          svg.collapse-arrow {
            width: rem(10);
            transform: rotateZ(-90deg);
            transition: transform 0.4s ease;

            path.arrow_icon_1 {
              fill: $tertiary-gray;
            }

            &.up {
              transform: rotateZ(-270deg);
            }
          }
        }
      }
    }

    .sub-items-container {
      transition: height 500ms;
      background-color: $page-divider;
      white-space: pre-wrap;
    }

    div.list-container {
      div.button-container {
        padding-bottom: rem(20);

        div.button-row {
          button {
            text-align: center;
            font-weight: bold;
          }
          display: flex;
          padding: rem(10) 0 rem(10) 0;
        }
      }

      div.faq-body-text {
        font-size: rem(14);
        font-weight: 500;
        text-align: left;
        background-color: $page-divider;
        border: none;
        line-height: 175%;
      }

      button {
        margin-top: rem(16);
        width: 100%;
      }
    }
  }
}
