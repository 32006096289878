@use "assets/styles" as *;

div.confirm-offers {
  height: 100%;
  position: relative;
  background: url(../../../assets/images/boomBoom_bg.jpg);
  overflow: scroll;
  padding: rem(20);

  > * {
    max-width: rem(600);
    margin-left: auto;
    margin-right: auto;
  }
  div.available-offers {
    background-color: white;
    border-radius: rem(15);
    margin-bottom: rem(100);

    div.available-offers-header {
      background-color: $sheetz-red;
      padding: rem(30) rem(20) rem(20);
      color: $white;
      border-top-right-radius: rem(10);
      border-top-left-radius: rem(10);

      p.offer-heading-text {
        @include reg-title;
      }

      p.offer-note {
        @include reg-body;
      }
    }

    div.checkout-available-rewardz {
      list-style-type: none;
      padding: rem(20);
      margin: rem(40) auto rem(24);

      li .offers-list-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: rem(10);
        border: rem(2) dashed $border;
        height: rem(100);
        padding: rem(12) rem(16);
        margin-bottom: rem(12);

        &.selected {
          border: rem(2) solid $sheetz-red;
        }

        .on-selection-icon-background {
          border-top-right-radius: rem(8);
          border-bottom-right-radius: rem(8);
          position: absolute;
          right: 0;
          height: 100%;
          width: 10%;
          background-color: $sheetz-red;

          .offer-check-icon {
            position: absolute;
            width: rem(18);
            margin: rem(35) 0 0 rem(5);
            @include desktop {
              margin-left: rem(18);
            }
          }

          svg.rewardz-side-tear {
            margin: rem(44) 0 0 rem(-50);
            height: rem(10);
            width: rem(98);
            transform: rotate(90deg);

            path {
              fill: $sheetz-red;
            }
          }
        }

        &.selected {
          svg .customization-check-circle {
            fill: $white;
          }

          .customization-check {
            fill: $sheetz-red;
          }
        }

        img {
          height: 100%;
          margin-right: rem(12);
        }

        p.offer-name {
          flex: 1;
          @include lg-body;
          @include mobile {
            margin-right: rem(20);
          }
        }
      }
    }
  }

  div.confirm-footer {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $primary-dark-gray;

    div.button-row-save-changes-button {
      display: flex;
      justify-content: center;
      margin: rem(25);

      .apply-button {
        padding: 0 rem(20);
        margin-left: rem(10);
      }

      button.order-cancel-button {
        padding: 0 rem(20);
      }
    }
  }
}
