@use "assets/styles/mixins" as *;
@use "assets/styles/functions" as *;

@mixin xl-title {
  @include obviously-narrow;
  font-size: rem(42);
  line-height: rem(50);
}

@mixin lg-title {
  @include obviously-narrow;
  font-size: rem(32);
  line-height: rem(40);
}

@mixin reg-title {
  @include obviously-narrow;
  font-size: rem(24);
  line-height: rem(34);
}

@mixin button-title {
  @include reg-title;
}

@mixin sm-title {
  @include obviously-narrow;
  font-size: rem(20);
  line-height: rem(30);
}

@mixin page-title {
  @include obviously-narrow;
  font-size: rem(22);
  line-height: rem(32);
}

@mixin xl-digit {
  @include obviously-narrow;
  font-size: rem(88);
  line-height: rem(96);
}

@mixin lg-title-bottom-margin {
  margin-bottom: rem(20);
}

@mixin reg-title-bottom-margin {
  margin-bottom: rem(12);
}
