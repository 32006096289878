@use "assets/styles" as *;

div.list-item-container {
  border-bottom: $default-border;
  padding: rem(20);
  display: flex;
  flex: 1;

  &.top-border {
    border-top: $default-border;
  }

  &.no-bottom-border {
    border-bottom: none;
  }

  &.no-top-padding {
    padding-top: 0;
  }

  &.no-left-padding {
    padding-left: 0;
  }

  > {
    margin: auto;
  }

  div.list-item-content-container {
    flex-grow: 1;
    margin-right: rem(5);
  }

  div.list-item-icon-container {
    align-self: center;
    margin-left: rem(20);

    svg {
      width: rem(10);
      transform: scaleX(-1);

      &.arrow-icon {
        &.accordion {
          transform: rotate(270deg);
        }

        &.expanded {
          transform: rotate(90deg);
        }
      }

      &.more-dots-icon {
        width: rem(4);
      }

      path {
        fill: $tertiary-gray;
      }
    }
  }
}

button.list-item-button.right-button {
  width: 15%;
  border-bottom: $default-border;
  text-align: center;

  &.top-border {
    border-top: $default-border;
  }

  &.no-bottom-border {
    border-bottom: none;
  }
}
