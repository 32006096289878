@use "assets/styles" as *;

p.restricted-item-error-text {
  text-align: left;
  line-height: 150%;
}

div.restricted-item-options-flex-container {
  display: flex;
  flex-direction: column;
  padding-top: rem(24);

  > * {
    margin: rem(8) 0;
  }
}
