@use "assets/styles" as *;

div.security-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  div.toggle {
    margin-right: rem(12);
  }
}
