@use "assets/styles" as *;

div.reset-form {
  margin: rem(20) 0;

  .submit-button {
    width: 100%;
    max-width: rem(400);
    margin: rem(10) 0;
  }
  .row {
    margin-bottom: rem(10);
  }
  div.reset-message {
    text-align: left;

    &.margin-bottom {
      margin-bottom: rem(20);
    }

    div.title {
      @include lg-title;
      margin: rem(10) 0;

      &.center {
        text-align: center;
      }
    }
  }
}
