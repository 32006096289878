@use "assets/styles" as *;

div.error-details-container {
  background-color: $white;
  padding: rem(20) rem(10) rem(20) rem(20);
  margin-top: rem(20);
  border-radius: rem(10);


  div.error-details {
    display: flex;
    flex-direction: column;

    div.error-heading {
      display: flex;
      @include xl-body;
      color: $primary-dark-gray;

      p {
        @include reg-body;
        margin-top: rem(10);
      }

      svg.alert-icon {
        width: rem(48);
        margin-right: rem(20);

        path,
        rect {
          fill: $error-orange;
        }
      }

    }

    div.error-details-button {
      margin-top: rem(10);

      button {
        width: 100%;
      }
    }
  }
}