@use "assets/styles" as *;

button.text-button {
  @include obviously;
  border: none;
  background-color: transparent;
  color: $white;
  font-size: rem(12);
  line-height: rem(16);
  text-decoration: underline;
  text-underline-position: under;

  &.dark {
    color: $primary-dark-gray;
  }

  &.disabled {
    opacity: 0.4;
  }

  &:hover {
    text-decoration: none;
  }
}
