@use "assets/styles" as *;

$minimum-spacing: rem(12);

div.pickup-location-container {
  padding: 0 rem(30);
  margin: auto 0;
  // This gives a bit of spacing between the order config component and this container.
  padding-top: $minimum-spacing;
  // This pushes the container upwards without adding the possibility of a collision.
  padding-bottom: calc(
    #{$header-height} + #{$order-config-height-with-pickup-time} + #{$minimum-spacing}
  );

  @include order-config-sidebar-padding;

  p.heading {
    color: $secondary-medium-gray;
  }

  ul {
    margin: rem(20) 0 0;
    list-style-type: none;
    padding: 0;

    li.in-store-pickup,
    li.curbside {
      &.disabled p.title {
        opacity: 0.5;
      }

      p.subtitle {
        color: $error-orange;
      }
    }
  }
}

div.mobile-app-required-action-sheet {
  p.action-sheet-message {
    margin: rem(24);
    font-size: rem(20);
    font-weight: bold;
    text-align: left;
    line-height: rem(32);
  }

  .action-sheet-button {
    width: 100%;
    margin-bottom: rem(12);
  }
}
