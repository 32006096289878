@use "assets/styles" as *;

div.offers-container {
  display: grid;
  column-gap: rem(10);
  row-gap: rem(10);
  border-radius: rem(15);
  width: 100%;
  margin-bottom: rem(10);
  background-color: $white;
  &.purchased {
    border-bottom: $border;
    border: 2px solid $sheetz-green;
  }

  img.offer-image {
    width: 100%;
    padding: rem(14) rem(8) 0 rem(8);
  }
  div.offer-contents {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img.offer-product-image {
      width: 30%;
      padding: rem(10);
    }

    div.offer-info {
      text-align: left;
      width: 70%;
      padding: 0 rem(20) 0 rem(10);

      div.offer-title {
        @include lg-body;
      }
      div.rebate-title {
        @include lg-body;
        padding-top: rem(20);
      }

      div.offer-description {
        @include sm-body;
        padding: rem(10) 0 rem(10) 0;
        line-height: 125%;
      }
      div.offer-expiration {
        @include sm-body;
        color: $tertiary-gray;
      }
    }
  }

  div.offer-btn-container {
    display: flex;
    height: rem(44);
    margin: 0 rem(20) rem(10) rem(20);
    justify-content: space-between;

    > button {
      height: inherit;
      &.confirm-btn {
        background-color: $sheetz-red;
      }
      &.remove-btn {
        margin-right: rem(25);
      }
    }
  }
  div.bybe-conditions-container {
    display: flex;
    border-top: rem(1) solid $border;
    justify-content: space-between;
    padding: rem(14) rem(20) 0 rem(20);
    @include tiny-body;

    div.bybe-icon {
      display: flex;
      svg.bybe-icon {
        width: rem(50);
        height: rem(30);
        margin: rem(-7) 0 0 rem(5);
      }
    }
    div.conditions {
      @include sm-body;
      margin-top: rem(-5);
      color: $primary-dark-gray;

      > button {
        &.conditions-text {
          @include sm-body;
          color: $primary-dark-gray;
        }
      }

      span.conditions-arrow {
        pointer-events: none;

        svg {
          width: rem(10);
          transform: rotateZ(-90deg);
        }
      }
      span.conditions-close-arrow {
        pointer-events: none;

        svg {
          width: rem(10);
          transform: rotateZ(90deg);
        }
      }
    }
  }
  div.offer-conditions {
    @include sm-body;
    padding: 0 rem(20);
    white-space: pre-wrap;

    div.view-conditions {
      display: flex;
      justify-content: center;
      padding: rem(10) 0 rem(20) 0;

      > button {
        &.close-conditions {
          @include sm-body;
          color: $primary-dark-gray;
          font-weight: bold;
        }
      }
    }
  }
  div.offer-label-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 rem(20) rem(20) rem(20);
    @include sm-body;

    div.offer-label {
      display: flex;

      svg.age-icon {
        width: rem(25);
        height: rem(25);
        margin: rem(-2) rem(5) 0 0;
      }
    }
  }
  div.offer-redemption {
    display: flex;
    justify-content: space-between;
    margin: 0 rem(20) rem(10) rem(20);
    @include sm-body;
    height: rem(18);
    div.offer-redemption-uses {
      color: white;
      padding: 0 rem(5) 0 rem(5);
      background-color: $sheetz-green;
    }
    div.offer-label {
      display: flex;

      svg.age-icon {
        width: rem(25);
        height: rem(25);
        margin: rem(-2) rem(5) 0 0;
      }
    }
  }
}
