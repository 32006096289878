@use "assets/styles" as *;

div.item-customization {
  div.item-customization-content {
    // ~ to tell webpack to use it's default module resolution instead of relative pathing.
    @include mobile {
      background-image: url(~assets/images/wood_bg_cropped.jpg);
      background-position: center top;
      background-size: 80%, contain;
      height: calc(100vh - (#{$header-height} + #{$item-customization-tabs-height}));
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
    @include desktop {
      background-image: none !important;
      height: calc(100vh - #{$header-height});
      overflow-y: hidden;
      display: grid;
      grid-template-columns: 25% auto 20%;
    }
    background-repeat: no-repeat;

    .customization-swoop {
      display: block;
      @include desktop {
        position: absolute;
        bottom: 0;
      }
      path {
        fill: $white;
      }
    }
    div.item-details-container {
      @include mobile {
        margin-top: 55%;
      }

      div.item-customization-image {
        position: relative;
        background-image: url(~assets/images/wood_bg_cropped.jpg);
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        img {
          width: 100%;
        }
      }

      div.item-details {
        display: flex;
        padding: 0 rem(20) rem(16);
        background-color: $white;
        justify-content: space-between;
        align-items: center;

        p.item-title {
          @include obviously-narrow;
          font-size: rem(24);
        }

        div.item-price-nutrition {
          display: flex;
          width: 20%;
          flex-direction: column;
          align-items: center;
          font-size: rem(10);
          color: $secondary-medium-gray;

          p.price {
            font-size: rem(16);
            font-weight: bold;
            margin: rem(4) 0;
          }
        }
      }

      div.combo-item-banner {
        display: flex;
        align-items: center;
        background-image: url(~assets/swoops/combo_title_swoop.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: rem(125);
        background-color: $white;
        padding-left: rem(16);

        img {
          width: 30%;
        }

        p {
          margin-left: rem(16);
          font-weight: bold;
        }
      }
    }

    .divider-container {
      background-color: $white;
      padding: rem(16) 0 rem(24);
      svg {
        display: block;
      }
    }

    div.item-customization-selectors {
      background-color: $white;
      @include desktop {
        overflow-y: scroll;
        padding: rem(10) 0 75vh 0;
        border-left: rem(5) solid $page-divider;
      }
    }

    div.item-customization-footer {
      display: block;
      background-color: $white;
      padding-bottom: 75vh;
      @include mobile {
        padding: rem(24) rem(24) 75vh rem(24);
      }
      @include desktop {
        margin: rem(24);
      }
      div.subtotal-container {
        width: 100%;
        margin: rem(36) 0;
        span.subtotal {
          float: right;
          font-weight: bold;
        }
      }
      button.add-to-bag-button,
      button.previous-item-button,
      button.next-item-button {
        width: 100%;
      }
      button.previous-item-button {
        margin-bottom: rem(16);
      }
    }
  }
}
button.aiu-no-thanks-button {
  margin: rem(20);
}

div.order-main-content.in-mobile-app div.item-customization div.item-customization-content {
  height: calc(100vh - #{$item-customization-tabs-height});

  div.item-customization-footer {
    padding-bottom: rem(64);
  }
}
