@use "assets/styles" as *;

div.login-form {
  button.registration-button,
  button.submit-button {
    width: 100%;
    max-width: rem(400);
    margin: 0 0 rem(10);
  }

  div.forgot-password {
    margin-top: rem(16);
    a {
      color: black;
    }
  }
}
