@use "assets/styles" as *;

div.header.standard {
  z-index: $auto-index;
}

.modal-close {
  display: flex !important;
}

.credit-card-action-sheet-label {
  @include lg-body;
  color: $primary-dark-gray;
  padding: rem(30) 0 rem(70);
}

.credit-card {
  height: 100%;
  padding-bottom: rem(20);
  .credit-card-form {
    padding: rem(20);
    height: 100%;

    .delete-button-container {
      padding-top: rem(75);
      padding-bottom: rem(25);
    }

    .delete-payment-button-label {
      text-align: center;
      flex: 1;
    }

    .delete-payment-button {
      margin-left: auto;
      margin-right: auto;
      padding: 0 rem(24);
      background-color: #ffffff;
      border: 0.125rem solid #343434;
      color: #343434;
      border-radius: 3.125rem;
      height: rem(48);
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }

    svg.trash-icon {
      border: none;
      height: rem(20);
      margin-right: rem(15);
    }

    .default-payment-row {
      padding-bottom: rem(20);
      border-bottom: $default-border;

      .default-payment-text {
        @include sm-body;
        text-align: left;
        color: $tertiary-gray;
        padding-bottom: rem(10);
      }
    }

    .button-wrapper {
      padding-bottom: rem(50);
      .button-row {
        height: rem(48);
        display: flex;
        align-items: center;

        button {
          flex-grow: 1;
          margin-top: rem(40);
        }
      }
    }

    .flex-row {
      display: flex;
      .credit-card-labels {
        flex: 1;
      }
    }
  }

  @media only screen and (max-height: rem(600)) {
    height: rem(500);
  }
}
