@use "assets/styles" as *;

ul.order-config-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include desktop {
    height: 100vh;
    padding-top: rem(140);
    float: left;
    width: rem(300);
    position: fixed;
    top: 0;
    border-right: rem(10) solid $page-divider !important;
  }

  li.heading-list-item {
    display: none;
    @include desktop {
      display: list-item;
      text-align: right;

      div.list-item-container {
        padding-right: rem(30);
      }
    }
  }

  li.store div.store-content-container {
    display: flex;
    align-items: center;

    > {
      margin: auto;
    }

    div.store-image-container {
      height: rem(65);

      img {
        height: 100%;
      }
    }

    div.store-address-container {
      flex-grow: 1;
      padding-left: 4%;

      p {
        margin: 0;
      }

      p.address {
        color: $primary-dark-gray;
        font-size: rem(16);
        @include desktop {
          font-size: rem(14);
        }
        font-weight: 500;
        line-height: rem(20);
      }

      p.directions {
        color: $secondary-medium-gray;
        font-size: rem(12);
        line-height: rem(16);
      }
    }
  }

  li.pickup-time {
    color: $primary-dark-gray;
    font-size: rem(16);
    font-weight: 500;

    .list-item-container {
      padding-left: rem(20);
    }
  }
}
