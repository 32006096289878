@use "assets/styles" as *;

div.empty-page-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 rem(24);

  div.empty-page {
    text-align: center;
    max-width: rem(500);
    margin-top: -$header-height;

    &.no-margin {
      margin-top: 0;
    }

    div.empty-page-title {
      font-size: rem(18);
      color: $secondary-medium-gray;
      font-weight: bold;
      padding: rem(20) 0 0 0;
    }

    div.empty-page-detail {
      font-size: rem(16);
      font-weight: normal;
      color: $tertiary-gray;
      padding-left: rem(10);
      padding-right: rem(10);
      padding-top: rem(10);
    }

    div.empty-page-icon-container {
      svg.empty-page-icon {
        height: rem(60);
        path {
          fill: $sheetz-red;
        }
      }
    }

    div.alert-icon {
      svg.empty-page-icon {
        path {
          fill: $error-orange;
        }
      }
    }

    div.empty-bag-icon {
      svg.empty-page-icon {
        path {
          fill: $border;
        }
      }
    }
  }
}
