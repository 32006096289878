@use "assets/styles" as *;

div.delivery-pickup-toggle {
  margin-top: rem(53);
  background-color: $sheetz-red;
  padding: 0 rem(48) rem(10);

  &.in-mobile-app {
    margin-top: 0;
  }

  label.switch {
    position: relative;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider:before {
        transform: translateX(99%);
      }

      &:focus {
        outline:none;
        outline: 0;
      }
    }
  }

  div.slider {
    display: flex;
    height: rem(36);
    cursor: pointer;
    border: rem(2) solid $white;
    border-radius: rem(36);
    transition: .4s;

    div.slider-contents {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      div {
        position: relative;
        z-index: $slider-content-index;
        width: 50%;

        p {
          @include lg-body;
          text-align: center;
          color: $white;

          &.selected {
            color: $primary-dark-gray;
          ;
          }
        }
      }
    }

    &:before {
      position: absolute;
      content: "";
      z-index: $slider-index;
      height: rem(34);
      width: 50%;
      bottom: rem(1);
      left: rem(1);
      background-color: $white;
      border-radius: rem(36);
      transition: .4s;
    }

  }
}

div.delivery-pickup-toggle-desktop {
  margin-bottom: rem(48);

  div.list-container {
    padding: 0;

    div.list-item-container {
      position: relative;
    }

    div.selected {
      div.selected-indicator {
        position: absolute;
        top: rem(8);
        right: rem(-7);
        height: rem(44);
        width: rem(4);
        border-radius: rem(12);
        background-color: $sheetz-red;
      }
    }
  }
}