@use "assets/styles" as *;

div.combo-item-pagination {
  background-color: $white;
  padding: rem(12) rem(20);

  div.combo-item-pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: $sheetz-red;
    padding: rem(8) rem(14) rem(7);
    border-radius: rem(10);
    color: $white;
    font-size: rem(14);
    font-weight: bold;
    width: 100%;

    div.pagination-dot {
      display: inline-block;
      margin: 0 rem(2);
      width: rem(8);
      height: rem(8);
      border: rem(1) solid $white;
      border-radius: 50%;

      &.current {
        background-color: $white;
      }
    }

    div.arrow-down {
      position: absolute;
      bottom: rem(-6);
      left: rem(40);
      width: 0;
      height: 0;
      border-left: rem(6) solid transparent;
      border-right: rem(6) solid transparent;
      border-top: rem(6) solid $sheetz-red;
    }
  }
}
