@use "assets/styles" as *;

div.sheetz-toggle {
  label.switch {
    position: relative;
    display: inline-block;
    width: rem(40);
    height: rem(24);

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: $sheetz-green;

        &:before {
          transform: translateX(rem(14));
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 rem(1) $sheetz-green;
      }
    }

    span.slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $body-bg;
      transition: .4s;
      border-radius: rem(34);

      &:before {
        position: absolute;
        content: "";
        height: rem(18);
        width: rem(18);
        left: rem(4);
        bottom: rem(3);
        background-color: $white;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }
}