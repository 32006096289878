@use "assets/styles" as *;

div.auth {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.52);
  @include mobile {
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100vw;
    &.sandwich {
      background-image: url(../../assets/images/anywhere_mobile620x560.jpg);
    }
    &.msc {
      background-image: url(../../assets/images/MSC_Main_Mobile_Center_new.jpg);
    }
  }
  @include desktop {
    background-image: url(../../assets/images/anywhere_desktop1280x800.jpg);
    background-size: cover;
    background-position: center;
  }
  div.authContainer {
    height: 100%;
    overflow-y: auto;
  }

  div.content {
    width: 100%;
    justify-content: center;
    text-align: center;
    overflow-y: unset;
    @include mobile {
      margin-top: 50%;
    }
    @include desktop {
      margin-top: rem(100);
      margin-left: auto;
      margin-right: 6.25rem;
      width: calc(50% - 20px);
      max-width: rem(500);
    }

    div.authTitle {
      position: relative;
      z-index: $auth-title-index;
      height: rem(183);
      @include obviously-narrow;
      background: url(../../assets/swoops/action_swoop.svg) bottom center;
      background-size: 100% 100%;
      font-size: rem(32);
      line-height: rem(36);
      text-align: left;
      padding: rem(70) rem(30) rem(85);
      color: $white;
    }

    .authChild {
      z-index: $auth-child-index;
      margin-top: rem(-32);
      padding: rem(60) rem(20) rem(20) rem(20);
      @include desktop {
        // 251 px covers the 100px margin on top, and the 183 pixel wide swoop,
        // minus the 32 pixel margin on the swoop bottom
        min-height: calc(100vh - 251px);
      }
      background-color: $white;
    }
  }
}
