@use "assets/styles" as *;

div.banner-background {
  height: 100vh;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.carousel ul.control-dots {
  top: 78%;
  margin-left: rem(-20);
  bottom: unset;
}

.banner-container-wrapper {
  position: fixed;
  top: 0;
  bottom: rem(80);
  width: 100%;
}

.title-text {
  position: absolute;
  bottom: rem(40);
  left: 50%;
  margin-left: -45%;
  width: 90%;
  padding: rem(10);
  @include obviously-narrow;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline $white;
  font-size: medium;
}
