@use "assets/styles" as *;

div.delivery-address-list-item {
  div.address-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }

    p.title {
      @include xl-body;
      color: $primary-dark-gray;
    }

    p.subtitle {
      @include lg-body;
      color: $secondary-medium-gray;
      margin-bottom: rem(-4);
    }

    p.special-instructions {
      @include tiny-body;
      margin-top: rem(4);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    button.delivery-address-icon {
      text-align: center;
      flex-grow: 0;
      width: rem(16);

      svg {
        width: rem(16);
        max-height: rem(16);
        path,
        rect {
          fill: $tertiary-gray;
        }
      }
    }
  }

}
