@use "assets/styles" as *;

$minimum-spacing: rem(12);
$delivery-address-height: rem(91);

div.delivery-time-container {
  padding: 0 rem(20);
  margin: auto 0;
  // This gives a bit of spacing between the order config component and this container.
  padding-top: $minimum-spacing;
  padding-bottom: calc(#{$header-height} + #{$delivery-address-height} + #{$minimum-spacing});

  @include order-config-sidebar-padding;

  p.heading {
    color: $secondary-medium-gray;
    margin-bottom: rem(20);
    padding: 0 rem(20);
  }
}
