@use "assets/styles" as *;

div.contact-us-form {
  padding: rem(16);
  margin-top: rem(70);

  &.form-submitted {
    height: 100vh;
    margin-top: 0;
  }

  svg.empty-page-icon {
    path {
      fill: $sheetz-green !important;
    }
  }

  button.empty-page-button {
    margin-top: rem(24);
  }

  label.message-label {
    font-size: rem(12);
    padding: rem(2) rem(10) rem(4);
  }

  textarea.message-input {
    border: $default-border;
    resize: none;
    height: rem(100);
    width: 100%;
    margin: rem(6) 0;
    font-family: "Roboto", sans-serif;
    font-size: rem(16);
    color: $secondary-medium-gray;
    padding: rem(11);
  }
  div.message-error {
    font-size: rem(12);
    height: rem(28);
    color: $error-orange;
  }

  div.recaptcha-row {
    text-align: center;
    > div > div > div {
      width: auto !important;
    }
  }

  div.button-row {
    text-align: center;
    .submit-button {
      width: 100%;
      max-width: rem(400);
      margin: rem(32) 0 rem(16) 0;
    }
  }
}
