@use "assets/styles" as *;

div.delivery-instructions {
  max-width: 48vw;

  @include desktop {
    padding-left: 0;
    max-width: rem(224);
  }

  h1 {
    @include lg-body;
    color: $primary-dark-gray;
    margin: 0 0 rem(-2);
  }

  p.instructions {
    @include sm-body;
    color: $primary-dark-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

div.delivery-instructions-action-sheet {
  p.header {
    @include reg-body;
    font-weight: bold;
    text-align: left;
    margin-bottom: rem(16);
  }

  button.save-delivery-instructions-button {
    width: 100%;
    margin-bottom: rem(16);
  }
}
