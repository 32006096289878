@use "assets/styles" as *;

div.reorder-guide {
  > div,
  > p {
    text-align: left;
    padding: rem(10);
  }

  p.reorder-text {
    font-weight: bold;
    font-size: rem(20);
    line-height: 125%;
  }

  p.reorder-subtext {
    font-weight: 500;
    font-size: rem(16);
    line-height: 150%;

    svg {
      width: rem(80);
      vertical-align: middle;
    }
  }

  div.reorder-buttons {
    padding: 0;
    margin: rem(20) 0;
    > button {
      width: 100%;
      margin: rem(10) 0;
    }
  }
}
