@use "assets/styles" as *;

div.faq-container {
  position: relative;
  margin: $header-height auto auto;
  height: calc(100vh - #{$header-height});

  div.filter-container {
    padding: rem(10) rem(16);
    border-bottom: rem(1) solid $border;

    div.topic-select-container {
      display: flex;
      align-items: baseline;

      p.topic-label {
        padding: rem(30) rem(15) rem(20) 0;
        font-weight: bold;
      }

      div.topic-select {
        width: 100%;
      }
    }

    @include desktop {
      padding: rem(10) 0;
    }
  }

  p.section-header {
    padding: rem(40) rem(16) rem(20);
    font-size: rem(18);
    color: $secondary-medium-gray;
    font-weight: bold;

    @include desktop {
      padding: rem(40) 0 rem(20);
    }
  }
}
