@use "assets/styles" as *;

div.code-entry-container {
  p.enter-code-here-label {
    @include obviously-narrow;
    text-align: left;
    font-size: rem(20);
    color: $primary-dark-gray;
  }
  p.code-entry-info {
    line-height: 150%;
    text-align: left;
    font-size: rem(14);
    color: $tertiary-gray;
    padding: rem(10) 0 rem(15);
    div.resend-code {
      display: inline-block;
      color: $sheetz-blue;
      text-decoration: underline;
    }
  }

  div.code-entry-continue-button {
    padding-top: rem(40);
    button.submit-button {
      width: 100%;
      max-width: rem(400);
      margin: rem(10) 0;
    }
  }

  div.cancel {
    margin-top: rem(16);
    a {
      color: black;
    }
  }
}
