@use "assets/styles" as *;

.item-quantity {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  p {
    @include sm-body;
    margin-right: rem(12);
    color: $secondary-medium-gray;
  }

  button {
    @include unstyled-button();
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: rem(44);
    height: rem(38);
    border: rem(1) solid $light-gray;
    border-radius: rem(10);
    padding: 0 rem(4);

    div.quantity {
      @include lg-body;
      color: $primary-dark-gray;
      flex: 1 0 auto;
    }

    &:disabled {
      width: rem(38);

      div.quantity {
        color: $tertiary-gray;
      }
    }

    div.quantity-selector {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: rem(10);
      height: rem(20);
      padding-right: rem(4);

      p {
        font-size: rem(10);
        color: $tertiary-gray;
        margin: 0;
        line-height: rem(10);
      }
    }
  }
}
