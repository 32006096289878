@use "assets/styles" as *;

div.sheetz-checkbox {
  padding: rem(15) 0;

  &.no-padding-top {
    padding-top: 0;
  }

  label {
    @include reg-body;
    display: grid;
    grid-template-columns: 1em auto;
    gap: rem(16);
    text-align: left;

    input[type="checkbox"] {
      appearance: none;
      margin: 0;
      width: rem(20);
      height: rem(20);
      border-radius: rem(4);
      display: grid;
      place-content: center;
      border: rem(1) solid $primary-dark-gray;

      &:checked {
        background-color: $sheetz-red;
        border-color: $sheetz-red;
      }

      &:disabled {
        border-color: rgba($color: $sheetz-red, $alpha: 0.6);

        + span {
          color: $tertiary-gray;
        }
      }

      &[disabled]:checked {
        border-color: rgba($color: $sheetz-red, $alpha: 0.05);
        background-color: rgba($color: $sheetz-red, $alpha: 0.4);
      }

      &.green {
        border: rem(1) solid $pay-green;

        &:checked {
          background-color: $pay-green;
        }

        &:disabled {
          border-color: rgba($color: $pay-green, $alpha: 0.6);
        }

        &[disabled]:checked {
          border-color: rgba($color: $pay-green, $alpha: 0.05);
          background-color: rgba($color: $pay-green, $alpha: 0.5);
        }
      }
    }

    input[type="checkbox"]::before {
      content: "";
      width: rem(11);
      height: rem(11);
      transform: scale(0);
      mask: url(~assets/icons/icon_check.svg) no-repeat 50% 50%;
      mask-size: cover;
      background-color: $white;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
  }
}
