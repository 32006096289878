@use "assets/styles" as *;

div.delivery-address-form {
  padding: rem(16);
  background-color: $white;

  .flex-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  div.column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  div.special-instructions {
    margin-top: rem(25);
  }
}
