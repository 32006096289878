@use "assets/styles" as *;

div.my-vehicles-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  div.list-container {
    padding: 0;
  }

  &.empty {
    padding: rem(150) 0 rem(100);
  }

  .user-vehicle-container {
    display: flex;
    align-items: center;
    padding-right: rem(30);
    @include xl-body;
    font-weight: bold;

    @include desktop {
      width: auto;
    }

    .user-vehicle-icon-label {
      flex: 1;
      padding-left: 4%;
      text-align: left;
    }
  }

  svg.trash-icon {
    height: rem(24);
    path.trash_icon_1 {
      fill: $tertiary-gray;
    }
  }
}

.user-vehicle-action-sheet {
  .user-vehicle-action-sheet-label {
    padding: rem(40) rem(20) rem(40) rem(0);
    font-weight: bold;
    line-height: rem(22);
  }

  .user-action-sheet-decline-btn-wrapper {
    width: 50%;
    @include desktop {
      width: 40%;
    }
  }
}
