@use "assets/styles" as *;

div.selector-tabs-container {
  width: 100%;
  overflow: visible;
  @include mobile {
    border-bottom: $default-border;
    height: $item-customization-tabs-height;
  }

  ul.selector-tabs {
    margin: 0;
    height: 100%;
    list-style-type: none;
    overflow-x: scroll;
    overflow-y: visible;
    @include mobile {
      padding: 0 rem(12) 0 rem(24);
      white-space: nowrap;
      max-width: 100%;
    }
    @include desktop {
      padding: 0;
    }

    -ms-overflow-style: none; /* IE/Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* Safari and Chrome */
      width: 0;
      height: 0;
      background: transparent;
    }

    li {
      display: inline-block;
      position: relative;
      height: 100%;
      line-height: rem(33);
      margin: 0 rem(12);
      padding: rem(12) rem(2);
      overflow-y: visible;
      @include desktop {
        width: 100%;
        margin-left: 0;
        padding-right: rem(12);
        border-top: rem(1) solid $page-divider;
        border-bottom: rem(1) solid $page-divider;
      }
      &:first-child {
        margin-left: 0;
      }

      button {
        @include unstyled-button;
        color: $secondary-medium-gray;
        text-decoration: inherit;
        font-size: rem(14);
        font-weight: bold;
        @include desktop {
          width: 100%;
          text-align: right;
          padding-right: rem(12);
        }
      }

      div.underline {
        border: rem(3) solid $sheetz-red;
        border-radius: rem(2);
        position: absolute;
        right: 0;
        bottom: 0;
        @include mobile {
          transform: scaleX(1) scaleY(0.5);
          left: 0;
        }
        @include desktop {
          transform: scaleX(0.5) scaleY(0.75);
          top: 0;
        }
      }
    }
  }
}
