@use "assets/styles" as *;

div.error-display {
  height: calc(100vh - #{$header-height});
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 rem(24);

  div.error-display-page {
    text-align: center;
    max-width: rem(500);
    margin-top: -$header-height;

    div.error-display-icon {
      svg.alert-icon {
        height: rem(80);
        l path {
          fill: $error-orange;
        }
      }
    }

    div.error-display-title {
      @include lg-body;
      padding: rem(10) 0 rem(10) 0;
    }

    div.error-display-message {
      @include reg-body;
    }

    button.error-button {
      margin: rem(20) auto;
      width: 100%;
      display: block;
    }
  }
}
