@use "assets/styles" as *;

p {
  margin: 0;
}

p.title {
  color: $primary-dark-gray;
  font-size: rem(18);
  font-weight: bold;
  line-height: rem(30);

  &.disabled {
    opacity: 0.5;
  }
}

p.subtitle {
  color: $secondary-medium-gray;
  font-size: rem(12);

  &.disabled {
    color: $error-orange;
  }
}
