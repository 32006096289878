@use "assets/styles" as *;

div.receipt-payment-detail {
  padding: 0 rem(10);
  color: $primary-dark-gray;

  p.heading {
    @include xl-body;
  }

  p.payment-detail-label {
    @include reg-body;
  }
}
