@use "assets/styles" as *;

.nav-menu {
  .bm-menu {
    background-color: $white;
  }

  *[tabindex]:focus {
    outline: none;
  }

  button {
    background-color: transparent;
    border-color: transparent;
  }

  header {
    position: relative;
    background: url(../../../assets/swoops/header_swoop.svg) bottom center no-repeat;
    background-size: 100% 100%;
    height: rem(115);

    .header-icons-container {
      display: flex;
      padding: rem(28) rem(20) 0;

      button {
        width: rem(30);
        height: rem(30);
        margin: auto;
        padding: 0;

        &:focus {
          outline: 0;
          border-radius: rem(8);
          border: rem(2) solid $white;
        }

        svg path {
          fill: $white;
        }
      }

      .sheetz-logo {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 50%;

        path.cls-2 {
          fill: $white;
        }
      }
    }
  }

  button.order-button {
    display: block;
    margin: rem(24) auto;
    width: 80%;
  }

  ul.nav-menu-items {
    list-style-type: none;
    margin: rem(16) 0 rem(78) 0;
    padding: 0;

    > li {
      border-bottom: $default-border;

      button:focus {
        outline: none;
        background-color: $page-divider;
      }

      > button {
        width: 100%;
        padding: rem(24) 0 rem(24) rem(30);
        color: $primary-dark-gray;
        font-size: rem(16);
        font-weight: bold;
        text-align: left;

        svg.collapse-arrow {
          float: right;
          margin: rem(4) rem(24) 0 0;
          width: rem(10);
          transform: rotateZ(-90deg);
          transition: transform 0.4s ease;

          path.arrow_icon_1 {
            fill: $tertiary-gray;
          }

          &.up {
            transform: rotateZ(-270deg);
          }
        }
      }

      .sub-items-container {
        transition: height 500ms;
      }

      ul.sub-items {
        list-style-type: none;
        padding: 0 0 rem(16) 0;

        button {
          padding: rem(12) 0 rem(12) rem(50);
          width: 100%;
          font-size: rem(14);
          font-weight: 500;
          text-align: left;
        }
      }
    }
  }

  p.copyright,
  p.version {
    padding-bottom: rem(24);
    font-size: rem(11);
    text-align: center;
  }

  p.copyright {
    padding-bottom: rem(4);
  }
}
