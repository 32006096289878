@use "assets/styles" as *;

.confirm-gift-card-purchase-container {
  height: calc(100% - #{$header-height});
  .confirm-gift-card-purchase-container-header {
    .header-row {
      display: block;
      padding-top: rem(30);
    }
    background-color: $pay-green;
    color: $white;

    .amount-header {
      @include obviously-narrow;
      font-size: rem(40);
      padding-bottom: rem(30);
    }
  }
  .confirm-gift-card-purchase-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
