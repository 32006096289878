@use "assets/styles" as *;

div.item-customization-selector {
  padding: 0 rem(16);

  &.no-padding {
    padding: 0;
  }
}

div.selector-divider-container {
  padding: rem(16) rem(16) rem(24);

  div.divider {
    border-top: rem(2) solid $border;
    opacity: 0.5;
  }
}
