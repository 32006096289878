@use "assets/styles/functions" as *;
@use "assets/styles/colors" as *;
@use "assets/styles/variables" as *;

.top-rip {
  position: relative;

  &::before {
    mask: url(/assets/svg/rip_top_short.svg);
    mask-repeat: repeat-x;
    mask-position: top;
    position: absolute;
    top: rem(-15);
    left: 0;
    height: rem(17);
    content: "";
    width: 100%;
  }
}

.bottom-rip {
  position: relative;

  &::after {
    mask: url(/assets/svg/rip_bottom_short.svg);
    mask-repeat: repeat-x;
    mask-position: bottom;
    position: absolute;
    bottom: rem(-15);
    left: 0;
    height: rem(17);
    content: "";
    width: 100%;
    z-index: $rip-svg-index;
  }
}

.top-rip.sheetz-green::before,
.bottom-rip.sheetz-green::after {
  background-color: $sheetz-green;
}

.top-rip.pay-green::before,
.bottom-rip.pay-green::after {
  background-color: $pay-green;
}

.top-rip.sheetz-red::before,
.bottom-rip.sheetz-red::after {
  background-color: $sheetz-red;
}

.top-rip.sheetz-white::before,
.bottom-rip.sheetz-white::after {
  background-color: $white;
}

.top-rip.body-bg::before,
.bottom-rip.body-bg::after {
  background-color: $body-bg;
}

.top-rip.off-white::before,
.bottom-rip.off-white::after {
  background-color: $off-white;
}

.top-rip.primary-dark-gray::before,
.bottom-rip.primary-dark-gray::after {
  background-color: $primary-dark-gray;
}