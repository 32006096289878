@use "assets/styles" as *;

div.payment-type-choice-container {
  padding: 0 rem(30);
  position: absolute;
  top: calc(50% - #{$header-height});
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: rem(400);

  @include desktop {
    max-width: $desktop-width-rem;
  }

  p.heading {
    color: $secondary-medium-gray;
  }

  ul {
    margin: rem(20) 0 0;
    padding: 0;
    list-style-type: none;
  }
}

p.payment-types-unavailable,
p.loyalty-error-message {
  text-align: left;
  line-height: 150%;
}

div.options-flex-container {
  display: flex;
  flex-direction: column;
  padding-top: rem(24);

  > * {
    margin: rem(8) 0;
  }

  button {
    &.in-mobile-app {
      margin-bottom: rem(20);
    }
  }
}

button.confirm-loyalty-error-button {
  margin: rem(24) 0 rem(32);
  width: 100%;
}
