@use "assets/styles" as *;

.order-main-content {
  position: relative;
  margin-top: $header-height;
  height: calc(100vh - #{$header-height});

  &.in-mobile-app {
    margin-top: 0;
    height: 100vh;
  }

  &.no-top-margin {
    margin-top: 0;
  }
}

div.bag-updates-actionsheet {
  text-align: left;
  ul {
    padding: rem(10) 0;
    list-style-type: none;
    font-weight: bold;
    li {
      margin: rem(12) 0;
    }
  }
  div.bag-update-buttons {
    margin-top: rem(32);
    height: rem(48);
    display: flex;
    align-items: center;
    button {
      flex-grow: 1;
    }
  }
}
