@use "assets/styles" as *;

div.driver-type {
  position: relative;
  background-color: $white;
  padding: rem(40) 0 0;

  p {
    @include reg-body;
    color: $primary-dark-gray;
    padding: 0 rem(20);

    &.help-text {
      margin: rem(20) 0;
      font-style: italic;
    }
  }

  label.driver-type-checkbox {
    border-bottom: $default-border;
    padding: rem(20);
    display: flex;
    flex: 1;

    &:last-of-type {
      border: none;
    }

    div.vehicle-flex-container {
      @include xl-body;
      font-weight: bold;
      margin: rem(-15) 0;
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      padding: rem(10) 0;

      div.driver-type-icon-container {
        display: flex;
        align-items: center;
        height: rem(60);
        width: rem(60);
        border: rem(1) solid $tertiary-gray;
        border-radius: rem(15);
        margin-left: rem(10);

        svg {
          width: rem(60);

          path {
            fill: $tertiary-gray;
          }

          &.car {
            width: rem(50);
            margin-left: rem(5);
          }
        }
      }

      div.driver-type-label {
        flex: 1;
        text-align: left;
        padding-left: 4%;
        color: $tertiary-gray;
      }

      &.selected {
        div.driver-type-icon-container {
          border-color: $sheetz-red;

          svg {
            path,
            rect {
              fill: $sheetz-red;
            }
          }
        }

        div.driver-type-label {
          color: $primary-dark-gray;
        }
      }

    }
  }
}

