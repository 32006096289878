@use "assets/styles" as *;

div.receipt-top-header {
  background-color: $sheetz-red;
  color: $white;
  padding-bottom: rem(26);

  p.alert {
    @include xl-body;
    background-color: $off-white;
    color: $primary-dark-gray;
    border-radius: rem(15);
    padding: rem(12) rem(20);
    margin: rem(20) rem(20) 0 rem(20);

    &.red {
      color: $sheetz-red;
    }
  }

  .top-content-flex-container {
    margin-top: rem(30);
    padding: 0 rem(30);

    div.greeting-date {
      flex: 1;
      overflow: hidden;

      p:not(:first-child) {
        margin-top: rem(4);
      }

      p.greeting {
        @include reg-title;
        // This truncates the text at 1 line and adds elipsis.
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p.sub-greeting {
        @include lg-body;
        // This truncates the text when it breaks to more than 2 lines and adds elipsis.
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p.date {
        @include xxl-body;
      }

      p.time,
      p.order-number {
        @include reg-body;
      }
    }

    div.order-total {
      text-align: right;

      p.number-of-items {
        @include xl-body;
      }

      .order-total-label {
        @include lg-title;
      }

      button {
        // Override default button padding to help with alignment.
        padding-right: 0;
      }
    }
  }

  div.barcode-container {
    margin: rem(30) rem(20) 0 rem(20);
  }
}
