@use "assets/styles" as *;

div.forgot-form {
  .submit-button {
    width: 100%;
    max-width: rem(400);
    margin: rem(10) 0;
  }
  .row {
    margin-bottom: rem(10);
  }
  div.forgot-message {
    margin: rem(20) 0;
    text-align: left;
    div.title {
      @include obviously-narrow;
      font-size: rem(32);
      margin: rem(10) 0;
    }
  }
  div.cancel {
    margin-top: rem(16);
    a {
      color: black;
    }
  }
}
