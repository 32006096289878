@use "assets/styles" as *;

.action-sheet-container {
  z-index: $action-sheet-container-index;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  &:not(.show) {
    display: none;
  }

  .action-sheet-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $action-sheet-overlay-index;
    background-color: transparent;
    transition: background-color 0.3s;
    &.show {
      background-color: rgba(0, 0, 0, 0.52);
      backdrop-filter: blur(0.5px);
    }
  }

  .action-sheet {
    z-index: $action-sheet-content-index;
    position: absolute;
    bottom: 0;
    min-width: rem(320);
    max-width: rem(500);
    width: 100%;
    overflow: auto;
    max-height: 100%;
    transform: translateY(130%);
    transition: transform 0.4s ease-in-out;

    &:not(.content) {
      bottom: 40%;
    }

    &.show {
      transform: translateY(0);
    }

    div.action-sheet-icon-container {
      width: rem(100);
      height: rem(100);
      position: relative;
      left: rem(20);
      top: rem(20);
      &.loading {
        top: rem(60);
      }
      svg.action-sheet-icon-circle {
        width: rem(140);
        circle {
          fill: $white;
        }
      }
      svg.action-sheet-icon {
        position: relative;
        top: rem(-124);
        left: rem(21);
        width: 80%;
        &.green {
          path {
            fill: $sheetz-green;
          }
        }

        &.currency {
          path {
            fill: $pay-green;
          }
        }

        &.red {
          path {
            fill: $sheetz-red;
          }
        }

        &.error {
          path {
            fill: $error-orange;
          }
        }

        &.pay {
          path {
            fill: $pay-green;
          }
        }
      }
    }

    .action-sheet-header {
      text-align: left;
      @include obviously-narrow;

      svg.swoop {
        &.green {
          path {
            fill: $sheetz-green;
          }
        }

        &.red {
          path {
            fill: $sheetz-red;
          }
        }

        &.currency {
          path {
            fill: $pay-green;
          }
        }

        &.error {
          path {
            fill: $error-orange;
          }
        }

        &.pay {
          path {
            fill: $pay-green;
          }
        }
        margin: unset;
      }

      svg.top-swoop {
        width: 100%;
        display: block;
        margin-bottom: rem(-4);
      }

      div.action-sheet-header-content {
        padding: rem(10) rem(30) rem(16);

        &.icon-present {
          padding-top: rem(20);
        }
        &.loading {
          padding-top: rem(80);
        }
        color: $white;
        &.green {
          background-color: $sheetz-green;
        }

        &.currency {
          background-color: $pay-green;
        }

        &.red {
          background-color: $sheetz-red;
        }

        &.error {
          background-color: $error-orange;
        }

        &.pay {
          background-color: $pay-green;
        }

        div.title {
          font-size: rem(36);
          line-height: rem(42);
        }

        div.text {
          padding-top: rem(4);
          font-size: rem(24);
          line-height: rem(28);
        }
      }

      svg.bottom-swoop {
        width: 100%;
        display: block;
        &.content {
          background-color: $white;
        }
      }
    }
    .action-sheet-content {
      padding: rem(20);
      background-color: $white;
      font-size: rem(16);
      &.required {
        padding-bottom: rem(20);
      }
    }
    .action-sheet-footer {
      background-color: $white;
      padding-bottom: rem(40);
    }
  }

  &.edge-to-edge .action-sheet .action-sheet-content {
    padding-left: 0;
    padding-right: 0;
  }
}
