@use "assets/styles" as *;

div.sheetz-input {
  position: relative;
  padding: rem(30) 0 rem(20) 0;

  button.showPassword {
    background-color: transparent;
    border: none;
    width: rem(36);
    position: absolute;
    top: rem(25);
    right: 0;
    display: block;
  }

  svg.showPasswordIcon {
    path {
      fill: $tertiary-gray;
    }
  }

  input {
    position: relative;
    width: 100%;
    border: 0;
    border-bottom: $default-border;
    outline: 0;
    color: $secondary-medium-gray;
    background-color: transparent;
    font-size: rem(16);
    padding: 0 rem(9) rem(8);
    box-sizing: border-box;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ label {
      top: rem(30);
      font-size: rem(16);
    }
    &:focus {
      ~ label {
        top: rem(10);
        font-size: rem(12);
      }
    }
  }

  &.password {
    input {
      padding-right: rem(36);
    }
  }

  label {
    position: absolute;
    top: rem(10);
    left: rem(10);
    display: block;
    transition: 0.3s;
    font-size: rem(12);
    color: $secondary-medium-gray;
  }

  &.error {
    input {
      border-color: $error-orange !important;
      color: $error-orange !important;
    }

    label {
      color: $error-orange;
    }

    div.error {
      font-size: rem(12);
      position: absolute;
      left: rem(10);
      bottom: 0;
      color: $error-orange;
    }
  }

  &.search {
    margin: rem(10) rem(20) 0;
    background: url(../../../../assets/icons/icon_search.svg) center left no-repeat;
    background-size: rem(20);

    input {
      padding-left: rem(36);
    }

    label {
      padding-left: rem(26);
    }

    .search-button {
      position: absolute;
      top: rem(23);
      right: 0;
    }
  }
}
