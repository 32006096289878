@use "assets/styles" as *;

.pin-creation-error-action-sheet-container {
  .pin-create-error-message {
    font-size: rem(16);
    color: $secondary-medium-gray;
    padding-bottom: rem(35);
    padding-top: rem(25);
  }
  .button-row {
    height: rem(48);
    align-items: center;
    button {
      width: 40%;
    }
  }
}
