@use "assets/styles" as *;

div.order-confirmation-details {
  div.order-confirmation-detail-list {
    background-color: $white;
    padding: rem(18) 0 0;
    border-radius: rem(10);
  }

  div.delivery-details {
    h1 {
      @include lg-body;
      color: $primary-dark-gray;
      margin: 0 0 rem(-8);
    }

    p {
      color: $primary-dark-gray;
      margin-top: rem(-8);
    }
  }

  div.store-address-container {
    padding: 0;

    p.directions {
      margin: 0 0 rem(4);
    }
  }

  svg.edit-icon,
  svg.map-icon,
  svg.phone-icon {
    width: rem(22);

    path,
    rect {
      fill: $tertiary-gray;
    }
  }

  svg.curbside-icon,
  svg.delivery-icon,
  svg.map-pin-icon {
    width: rem(48);
  }

  svg.store-icon {
    width: rem(32);
  }

  p.pickup-time {
    margin-bottom: rem(4);
  }

  p.pickup-time,
  p.pickup-location {
    font-size: rem(12);
    font-weight: bold;
    line-height: rem(20);
  }
}
