@use "assets/styles" as *;

div.no-option-switch-selector,
div.multi-option-switch-selector {
  svg.top-swoop,
  svg.bottom-swoop {
    height: rem(70);
    width: 100%;
    display: block;
  }

  svg.top-swoop {
    margin-bottom: rem(-2);
  }

  svg.bottom-swoop {
    margin-top: rem(-2);
  }

  div.selector-content {
    background-color: $sheetz-red;
    padding: 0 rem(20);

    p.selector-title,
    p.selector-sub-title {
      color: $white;
      @include obviously-narrow;
      font-size: rem(32);
      margin-left: rem(12);
      margin-bottom: rem(12);
    }

    p.selector-sub-title {
      font-size: rem(20);
      margin: rem(16) 0 rem(24);
      margin-left: rem(12);
    }

    div.condiment-grid {
      margin: 0 !important;
    }
  }

  svg.bottom-swoop {
    height: rem(85);
  }

  // Themes

  &.DEFAULT {
    svg.swoop path {
      fill: $sheetz-red;
    }

    div.selector-content {
      background-color: $sheetz-red;
    }
  }

  &.SOS_BLUE {
    svg.swoop path {
      fill: #3f9fd5;
    }

    div.selector-content {
      background-color: #3f9fd5;
    }
  }

  &.SOS_GREEN {
    svg.swoop path {
      fill: #8cb500;
    }

    div.selector-content {
      background-color: #8cb500;
    }
  }

  &.PURPLE {
    svg.swoop path {
      fill: #8654a9;
    }

    div.selector-content {
      background-color: #8654a9;
    }
  }
}
