@use "assets/styles" as *;

div.restricted-item-delivery-terms {
  p {
    @include sm-body;
    text-align: left;
  }

  div.instructions {
    margin-bottom: rem(16);

    span.number {
      display: inline-block;
      width: rem(16);
    }

    p.instruction {
      margin-left: rem(16);
    }
  }

  p.disclaimer {
    margin: rem(24) 0 rem(16);
  }

  button {
    width: 100%;

    &.in-mobile-app {
      margin-bottom: rem(20);
    }
  }
}
