@use "assets/styles" as *;

.sheetz-text-area {
  display: block;
  width: 100%;
  border: $default-border;
  resize: none;
  margin-bottom: rem(64);
  border-radius: rem(12);
  padding: rem(16);
  font-size: rem(14);
  font-family: "Roboto", sans-serif;
}
