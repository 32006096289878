@use "assets/styles" as *;

div.personal-information-form {
  position: relative;
  background-color: $white;

  div.personal-information-list-items {
    border-bottom: $default-border;

    span.transactional-email-subtitle {
      color: $error-orange;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: rem(16);

    @include desktop {
      overflow-y: initial;
    }

    div.personal-information-container {
      margin-top: rem(20);
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      .address-line-2-input-container {
        width: 80%;
        float: left;
      }

      .address-line-2-optional {
        width: 20%;
        float: right;
        font-size: rem(12);
        padding-top: rem(35);
        margin-bottom: rem(20);
        border-bottom: $default-border;
        text-align: right;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    p {
      color: $primary-dark-gray;
      margin: rem(36) 0 rem(16);

      &.text-message-optin {
        @include sm-body;
        margin-top: rem(8);
      }

      &.employee-information {
        margin-top: rem(30);
      }
    }
  }
}
