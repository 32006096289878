@use "assets/styles" as *;

div.delivery-address-action-sheet {
  p.delivery-address-message {
    font-size: rem(14);
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    margin-bottom: rem(20);
    padding: 0 rem(10);
  }

  div.button-container {
    padding: 0 rem(10);

    button.delivery-address-confirm-button {
      width: 100%;

      &:last-child {
        margin: rem(16) 0;
      }
    }
  }
}
