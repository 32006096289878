@use "assets/styles" as *;

div.available-rewardz-button-container {
  padding: rem(10) rem(20);
  background-color: $sheetz-red;
}

button.available-rewardz-button {
  width: 100%;

  &.margin-top {
    margin-top: rem(24);
  }

  svg {
    transform: scaleX(-1);

    path {
      fill: $white;
    }
  }
}