@use "assets/styles" as *;

p.alert-action-sheet-message {
  margin-top: rem(32);
  font-weight: bold;
  font-size: rem(18);
  text-align: left;
  padding: 0 rem(16);
}

button.confirm-button {
  width: 100%;
  margin-top: rem(64);

  &.in-mobile-app {
    margin-bottom: rem(20);
  }
}
