@use "assets/styles" as *;

div.delivery-address {
  h1 {
    @include lg-body;
    color: $primary-dark-gray;
    margin: 0 0 rem(1);
    text-align: left;
  }

  p.delivery-address-street,
  p.delivery-address-unit {
    margin-bottom: rem(-4);
    color: $secondary-medium-gray;
  }

  p.delivery-address-street,
  p.delivery-address-unit,
  p.delivery-city-state-zip {
    @include lg-body;
    color: $secondary-medium-gray;
  }
}
