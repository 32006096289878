@use "assets/styles" as *;

.choose-payment-method-container {
  .choose-payment-method-label {
    color: $tertiary-gray;
    font-size: rem(16);
    font-weight: bold;
    padding-top: rem(40);
  }
  @include desktop {
    max-width: rem(600);
    margin: auto;
  }
  .payment-method-selected {
    border: rem(2) solid;
    border-color: $pay-green;
    border-radius: rem(15);
  }
  .next-button {
    padding-top: rem(200);
  }
  .button-row {
    height: rem(48);
    display: flex;
    align-items: center;

    button {
      flex-grow: 1;
      margin-top: rem(40);
    }
  }
  .payment-method-item {
    padding-top: rem(10);
    padding-bottom: rem(10);
    margin-top: rem(10);
    margin-bottom: rem(10);
    button {
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      padding: rem(10);
      div {
        display: grid;
        grid-template-columns: rem(50) auto rem(60);
        margin: auto;
        div {
          display: block;
        }
        .payment-method-icon-container svg {
          width: rem(32);
          height: rem(32);
        }
        .payment-method-label-container {
          margin: auto rem(12);
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            line-height: rem(16);
            margin: rem(8) auto rem(4);
            &.payment-method-name {
              font-weight: bold;
              font-size: rem(16);
              color: $primary-dark-gray;
            }
            &.payment-method-number {
              color: $tertiary-gray;
            }
            &.payment-method-number-expired {
              color: $error-orange;
            }
          }
        }
        .payment-method-default-container {
          font-size: rem(12);
          color: $tertiary-gray;
          .payment-method-update-label {
            color: $sheetz-blue;
          }
          .refresh-icon {
            width: rem(32);
            path {
              fill: $primary-dark-gray;
            }
          }
        }
      }
    }
    &.placeholder button div {
      .payment-method-icon-container {
        background-color: $border;
        width: rem(50);
        height: rem(50);
        margin: auto;
        border-radius: rem(10);
      }
      .payment-method-label-container p {
        height: rem(16);
        background-color: $border;
        border-radius: rem(4);
      }
    }
  }
  .add-payment-method-row {
    width: 100%;
    text-align: center;
    button.add-payment-method-button {
      margin: rem(30) auto 0;
      width: 90%;
      max-width: rem(400);
    }
  }
}
