@use "assets/styles" as *;

div.remove-item-container {
  div.remove-item-header {
    font-weight: bold;
    padding-bottom: rem(16);
  }
  div.bag-item {
    border-bottom: unset;
  }
  div.remove-item-flex-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: rem(16) 0;

    button.remove-item-button {
      width: 50%;
    }
  }
}
