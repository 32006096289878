@use "assets/styles" as *;

div.receipt-order-detail {
  padding: rem(44) rem(20) rem(30);
  border-bottom: rem(1) solid $border;

  .order-detail-flex-container > div {
    flex: 1;
  }

  .clock-icon {
    width: rem(24);
    height: rem(24);

    path {
      fill: $primary-dark-gray;
    }
  }

  p.time-label,
  p.type-label {
    @include reg-body;
    color: $secondary-medium-gray;
  }

  p.time,
  p.type {
    @include page-title;
    // Override line height to help with spacing.
    line-height: rem(22);
    color: $primary-dark-gray;

    // The Obviously font has a larger gap at the top than bottom of a line,
    // this attempts to correct that.
    span {
      vertical-align: super;
    }
  }

  .order-type-icon {
    width: rem(34);
    height: rem(34);

    &.red path {
      fill: $sheetz-red;
    }

    &.blue path,
    &.blue polygon {
      fill: $sheetz-blue;
    }

    &.green path {
      fill: $pay-green;
    }
  }
}
