@use "assets/styles" as *;

div.pick-up-time-form {
  div.flex-container {
    display: flex;
    justify-content: center;

    select {
      border-bottom: none;
      color: $primary-dark-gray;
      font-weight: bold;
      font-size: rem(20);
      padding-left: rem(10);
      padding-right: rem(10);
    }

    select[name="hour"] {
      border-left: $default-border;
    }

    select[name="minute"] {
      border-right: $default-border;
    }

    span.time-separator {
      align-self: center;
      font-size: rem(24);
      color: $tertiary-gray;
    }
  }

  p.error {
    height: rem(12);
    color: $error-orange;
    font-size: rem(12);
    font-weight: 500;
  }

  .submit-button {
    width: 100%;
    max-width: rem(400);
    margin: rem(16) 0;
  }
}
