@use "assets/styles" as *;

div.delivery-addresses-container {
  @include order-config-sidebar-padding;
  flex: 1;

  &.padding-bottom {
    padding-bottom: rem(90);
  }

  p.heading {
    color: $tertiary-gray;
    font-size: rem(14);
    font-weight: bold;
    padding-left: rem(20);
    margin-top: rem(55)
  }

  p.no-delivery-addresses {
    margin: rem(20) rem(5);
  }

  div.empty-delivery-addresses {
    height: calc(100vh - #{$header-height});

    button {
      margin-top: rem(20);
    }
  }
}

