@use "assets/styles" as *;

div.enter-pin-container {
  min-width: rem(320);
  div.enter-pin-body {
    background-color: $white;
    text-align: left;
    padding: rem(5);
    div.enter-memorable-label {
      padding: rem(10) 0 rem(20) rem(5);
      color: $secondary-medium-gray;
      font-size: rem(18);
      .span {
        font-weight: bold;
      }
      .four-digit-label {
        font-weight: bold;
      }
    }
    div.error-message {
      color: $error-orange;
      padding-bottom: rem(15);
      padding-left: rem(5);
    }
    div.pin-entry-need-help-container {
      line-height: 125%;
      padding-top: rem(30);
      color: $secondary-medium-gray;
      font-size: rem(16);
      text-align: center;
      padding-bottom: rem(20);
    }
    .button-row {
      height: rem(48);
      display: flex;
      @include desktop {
        margin-bottom: rem(20);
      }
      .pin-buttons {
        width: 50%;
        .pin-back-button {
          border: none;
          text-decoration: underline;
          background-color: $white;
          color: $secondary-medium-gray;
          font-weight: bold;
          font-size: rem(16);
          width: 100%;
          padding-top: rem(13);
        }
        .pin-continue-button {
          width: 100%;
          .submit-button {
            width: rem(150);
            @include desktop {
              width: rem(200);
            }
          }
        }
      }
    }
  }
}

div.pin-entry-error-action-sheet-container {
  .pin-entry-error-message {
    line-height: 125%;
    text-align: left;
    font-size: rem(16);
    color: $secondary-medium-gray;
    padding-bottom: rem(35);
    padding-top: rem(25);
  }
  .button-row {
    height: rem(48);
    align-items: center;
    width: 100%;
    button {
      min-width: rem(200);
      @include mobile {
        width: 90%;
      }
    }
  }
}
