@use "assets/styles" as *;

div.favorite-my-order {
  div.save-order-button-flex-container {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: rem(16) 0 0 rem(26);

    div.circle {
      height: rem(30);
      width: rem(30);
      border: $default-border;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: rem(16);

      svg {
        width: 60%;
        // Pull the icon up slightly - this seems to center it better in the circle.
        margin-top: rem(-1);

        &.favorite path {
          fill: $sheetz-red;
        }

        path {
          fill: $border;
        }
      }
    }

    p.label {
      font-weight: bold;
    }
  }
}
