@use "assets/styles" as *;

div.preferences-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }
}
