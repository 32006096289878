@use "assets/styles" as *;

div.notifications-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  div.toggle {
    margin-right: rem(12);

    &.transactional-emails {
      display: flex;
      @include reg-body;
    }
  }
}

