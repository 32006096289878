@use "assets/styles" as *;
@import-normalize;

html {
  box-sizing: border-box;
  font-size: $base-font-size * 1px;

  &.menu-open {
    overflow: hidden;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: $primary-dark-gray;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Some agents reset button fonts back to Arial.
  button {
    font-family: "Roboto", sans-serif;
  }
}

[data-whatintent="mouse"] *:focus,
[data-whatintent="touch"] *:focus {
  outline: none !important;
}

// This styling ensures the visa branding SDK displays content correctly.
div#visa-sensory-branding {
  display: none;
  position: absolute;
  top: 0;

  &.show {
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
