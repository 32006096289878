@use "assets/styles" as *;

div.order-detail-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: rem(18);
  border-top: $default-border;

  &:first-of-type {
    padding-top: 0;
    border-top: none;
  }

  div.order-detail-list-item-icon {
    display: flex;
    justify-content: center;
    width: rem(40);
    margin-right: rem(12);

    svg {
      path {
        fill: $tertiary-gray;
      }
    }
  }

  div.order-detail-list-item-content {
    flex: 1 0 auto;
    padding-right: rem(18);

    &.no-flex {
      flex: initial;
    }

    p {
      @include reg-body;
    }
  }
}
