@use "assets/styles" as *;

div.edit-mysheetzcard {
  div.loyalty-card-barcode {
    padding: rem(20) rem(20) 0 rem(20);
  }
  form.edit-mysheetzcard-form {
    padding: rem(20);

    div.preferred-row {
      margin: rem(20);
      text-align: left;

      label {
        margin-right: rem(20);
      }

      input {
        background-color: transparent;
        margin-right: rem(15);
      }
    }

    div.button-row {
      height: rem(48);
      display: flex;
      align-items: center;
      button {
        flex-grow: 1;
      }
    }
  }
  div.delete-row {
    text-align: center;
    .delete-payment-button-label {
      flex: 1;
    }

    .delete-payment-button {
      padding: 0 rem(24);
      margin-left: rem(90);
      background-color: #ffffff;
      border: 0.125rem solid #343434;
      color: #343434;
      border-radius: 3.125rem;
      height: rem(48);
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      align-items: center;
      width: rem(180);
      @include desktop {
        margin-left: rem(150);
      }
    }

    svg.trash-icon {
      border: none;
      height: rem(20);
      margin-right: rem(4);
    }
  }
}
