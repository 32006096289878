@use "assets/styles" as *;

div.unavailability-action-sheet-container {
  padding: 0 rem(12);

  div.unavailability-details-container {
    text-align: left;
    font-weight: bold;
    font-size: rem(18);
    margin: rem(16) 0;
    line-height: 150%;
  }

  button {
    width: 100%;
    margin-top: rem(16);
  }
}
