@use "assets/styles" as *;

div.change-size-container {
  background-color: $white;
  text-align: center;
  padding: 0 rem(20) rem(16);

  button.change-size-button {
    @include unstyled-button;
    border: $default-border;
    border-radius: rem(14);
    width: 100%;
    padding: rem(16) rem(20);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      outline-color: none;
      border-color: $primary-dark-gray;
    }

    p.size-description {
      color: $primary-dark-gray;
      font-size: rem(14);
      font-weight: bold;
    }
    p.change-label {
      color: $secondary-medium-gray;
      font-size: rem(10);
    }
  }
}

div.warning-flex-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: rem(16) 0;

  button.continue-button {
    width: 50%;
  }
}
