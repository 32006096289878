@use "assets/styles" as *;

div.create-delivery-addresses-container {
  margin-top: rem(16);

  @include order-config-sidebar-padding;

  div.delivery-address-form {
    max-width: none;
  }
}

