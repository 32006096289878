@use "assets/styles" as *;

.amount-selector-container {
  display: flex;
  max-width: rem(600);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: rem(15);
  align-items: center;

  button.amount-select {
    flex-grow: 1;
    min-width: rem(60);
    width: 100%;
    margin: 0 rem(5);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      margin: 0;
    }
  }
}
