@use "assets/styles" as *;

div.sheetz-radio-group {
  margin-bottom: rem(32);

  p.sheetz-radio-group-text {
    @include lg-body;
    margin-bottom: rem(12);
  }

  p.sheetz-radio-group-error {
    @include reg-body;
    color: $sheetz-red;
    margin-bottom: rem(10);
  }

  div.top-margin {
    margin-top: rem(8);
  }
}

