@use "assets/styles" as *;

div.delivery-pickup-sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  background: $sheetz-red;
  padding: rem(12) rem(16);

  &.no-padding {
    padding: 0;
  }

  &.reduce-bottom-padding {
    padding-bottom: rem(6);
  }

  div.location-type-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-right: rem(2) solid $white;
    margin-right: rem(6);
    padding-right: rem(10);

    @include desktop() {
      margin-right: rem(8);
    }

    p {
      @include sm-body;
      color: $white;
      font-weight: bold;
      margin-left: rem(12);
    }
  }

  div.store-information-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 auto;
    padding-right: rem(5);
  }

  svg.delivery-pickup-icon,
  svg.location-icon {
    height: rem(24);

    path {
      fill: $white;
    }
  }

  svg.location-icon {
    margin-right: rem(4);
  }
}
