@use "assets/styles" as *;

div.create-pin-container {
  min-width: rem(320);

  div.create-pin-header {
    text-align: left;
    padding: rem(20);

    div.lock-it-up-label {
      padding-bottom: rem(10);
      @include obviously-narrow;
      font-size: rem(16);
    }

    div.create-a-pin-label {
      padding-bottom: rem(30);
      @include obviously-narrow;
      font-size: rem(36);
    }

    div.security-message {
      padding-bottom: rem(5);
      font-size: rem(16);

      .security-message-padding {
        padding-bottom: rem(10);
      }
    }
  }

  div.create-pin-body {
    background-color: $white;
    text-align: left;
    padding: 0 rem(20) rem(20);

    p {
      margin: 0 0 rem(10);
      line-height: rem(20);
      color: $secondary-medium-gray;

      &.reset-pin-text {
        font-size: rem(12);
        font-weight: 500;
        margin-bottom: rem(20);
      }
    }

    div.pin-validations {
      display: flex;
      flex-direction: column;
      font-size: rem(12);
      line-height: rem(20);
      margin-bottom: rem(20);

      div.pin-validation {
        display: flex;
        flex-direction: row;
        align-items: center;

        span.checklist-indicator {
          width: rem(16);
          height: rem(16);
          margin: 0 rem(10) 0 0;
          .indicator-circle,
          .indicator-icon {
            position: relative;
          }
          .indicator-circle {
            position: relative;
            left: 0;
            width: rem(16);
            circle {
              fill: $border;
            }
            &.success {
              circle {
                fill: $sheetz-green;
              }
            }
            &.fail {
              circle {
                fill: $error-orange;
              }
            }
          }
          .indicator-icon {
            position: relative;
            top: rem(-22);
            width: rem(12);
            margin: rem(2);
            path {
              fill: $white;
            }
          }
        }
      }
    }

    h3 {
      margin: rem(40) 0 rem(10);
    }

    .four-digit-label {
      font-weight: bold;
    }

    .step-text {
      margin-bottom: rem(15);
    }

    .error-message {
      color: $error-orange;
      padding: 0 0 rem(15);
    }

    .button-row {
      height: rem(48);
      display: flex;
      margin-top: rem(20);

      @include desktop {
        margin-bottom: rem(20);
      }

      .pin-buttons {
        width: 50%;
        .pin-back-button {
          border: none;
          text-decoration: underline;
          background-color: $white;
          color: $secondary-medium-gray;
          font-weight: bold;
          font-size: rem(16);
          width: 100%;
          padding-top: rem(13);
        }
        .pin-continue-button {
          width: 100%;
          .submit-button {
            width: rem(150);
            @include desktop {
              width: rem(200);
            }
          }
        }
      }
    }
  }
}
