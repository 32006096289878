@use "assets/styles" as *;

div.sub-header,
div.employee-sub-header {
  display: flex !important;
  background: url(../../../../assets/swoops/action_swoop.svg) bottom center no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  width: 100%;
  height: rem(154);
  margin-top: rem(-30);
  padding: rem(20) rem(19) rem(30);

  @include desktop {
    height: rem(185);
    margin-top: rem(-35);
  }

  svg.tier-icon,
  svg.zsquad-icon {
    width: rem(65);
    path.zsquad_icon_1 {
      fill: $white;
    }
    path.zsquad_icon_2 {
      fill: $sheetz-red;
    }
  }

  > div {
    margin: auto;

    p {
      color: $white;
      font-size: rem(14);
      font-weight: bold;
      margin-bottom: rem(8);

      &.tier-expires-label,
      &.tier-maintain-label {
        font-size: rem(10);
        line-height: 135%;
        margin-bottom: rem(5);
      }
    }
  }

  div:last-child {
    flex: 1;
    padding-left: rem(12);
  }
}

div.employee-sub-header {
  background: url(../../../../assets/swoops/action_swoop_reverse.svg) bottom center no-repeat;
  background-size: cover;
  height: rem(190);

  @include desktop {
    background-size: rem(480);
    height: rem(250);
    margin-top: rem(-50);
  }
}
