@use "assets/styles" as *;

div.edit-favorite-container {
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;

    div.favorite-details-scroll-container {
      flex-grow: 1;
      padding: rem(20) 0 rem(96);
      overflow-y: auto;

      @include desktop-max-width {
        width: 100%;
        overflow-y: initial;
      }

      div.edit-favorite {
        padding: 0 rem(20);

        div.sheetz-input {
          padding-bottom: 0;
        }
      }

      ul.favorite-order-item-list {
        list-style-type: none;
        padding-left: 0;
      }

      div.subtotal-row {
        font-size: rem(14);
        padding: 0 rem(20);
        margin-bottom: rem(32);
        font-weight: bold;
        display: flex;
        justify-content: space-between;
      }

      button.delete-favorite-button {
        width: 100%;
        max-width: rem(320);
        display: block;
        margin: auto;
      }
    }

    div.edit-favorite-footer {
      padding: rem(20) rem(20) rem(10) rem(20);
      align-items: center;
      background-color: $primary-dark-gray;
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
        max-width: rem(320);
        margin-bottom: rem(10);
      }
    }
  }
}

div.in-mobile-app {
  form div.edit-favorite-footer {
    padding-bottom: rem(42);
  }
}
