@use "assets/styles" as *;

div.my-receipts-container {
  height: 100%;
  margin: 0 auto;
  max-width: rem(600);

  @include desktop() {
    max-width: $desktop-width-rem;
  }

  ul.history-list {
    list-style-type: none;
    @include mobile() {
      margin: auto rem(10);
    }
    padding: 0;
  }

  div.reorder-empty-page-buttons {
    margin-top: rem(10);
    display: inline-grid;
    width: rem(200);
    > button {
      margin-bottom: rem(10);
    }
  }

  div.no-receipts-container {
    height: 100%;
    margin: 0 auto;
  }
}
