@use "assets/styles" as *;

div.action-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: rem(20) rem(40);
  border-radius: rem(20);
  width: rem(350);

  svg.icon-default-store {
    width: rem(90);

    rect {
      fill: $sheetz-red;
    }
  }

  h1 {
    @include lg-title;
    text-align: center;
  }

  p {
    @include lg-body;
    margin-bottom: rem(20);
    text-align: center;
  }
}