@use "assets/styles" as *;

div.add-new-vehicle-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  div.list-container {
    padding: 0;
  }

  div.vehicle-container {
    @include xl-body;
    font-weight: bold;
    margin: rem(-15) 0;

    .vehicle-flex-container {
      display: flex;
      align-items: center;
      padding: rem(10) 0;

      svg {
        height: rem(60);
        border: rem(1) solid $tertiary-gray;
        border-radius: rem(15);
        path {
          fill: $tertiary-gray;
        }
      }
      .vehicle-icon-label {
        flex: 1;
        text-align: left;
        padding-left: 4%;
      }
    }

    .showOptions {
      padding-top: rem(35);
    }

    .hideOptions {
      display: none;
    }

    @include desktop {
      width: auto;
    }
  }
}


div.empty-add-vehicle-container {
  text-align: center;
  max-width: rem(700);
  @include desktop {
    margin-right: auto;
    margin-left: auto;
  }
}
