@use "assets/styles" as *;

button.sheetz-container-button {
  @include unstyled-button();
  display: block;
  color: $primary-dark-gray;
  width: 100%;
  text-align: inherit;

  &.disabled {
    opacity: 0.4;
  }

  &.list-item-button {
    &.disabled {
      &:active {
        transform: initial;
      }
    }
  }
}
