@use "assets/styles" as *;

div.payment-footer-tip-manager {
  margin-bottom: rem(20);

  @include desktop {
    padding: 0 rem(20);
  }

  p.prompt-text {
    color: $white;
    font-size: rem(12);
    margin-bottom: rem(12);
  }

  div.tip-choices-flex-container {
    display: flex;

    > button:first-child {
      margin-left: 0;
    }

    > button:last-child {
      margin-right: 0;
    }
  }
}
