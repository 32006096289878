@use "assets/styles" as *;

div.my-addresses-container {
  position: relative;
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  @include desktop {
    padding-top: 0;
  }

  &.empty {
    padding: rem(150) 0 rem(100);
  }

}
