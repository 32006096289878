@use "assets/styles" as *;

div.price-display {
  p {
    @include sm-body;
    color: $primary-dark-gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: right;

    &.item-original-price {
      color: $tertiary-gray;
      -webkit-line-clamp: 3;
    }

    &.item-price-discount {
      color: $sheetz-red;
      -webkit-line-clamp: 3;
    }

    &.item-total {
      @include lg-body;
    }
  }
}
