@use "assets/styles" as *;

div.quantity-selector-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: rem(120);
  padding-bottom: rem(10);
  gap: rem(8);

  button.quantity-selector {
    @include unstyled-button();
    position: relative;
    color: $primary-dark-gray;
    font-size: rem(32);
    height: unset;
    border: $default-border;
    &.selected {
      border-color: $sheetz-red;
    }
    border-radius: rem(10);
    .customization-check-icon {
      position: absolute;
      width: 15%;
      top: 10%;
      right: -7%;
    }
  }
}
