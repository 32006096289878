@use "assets/styles" as *;

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.inline-loading-content {
  background-image: url(~assets/icons/loader_circle.svg);
  width: rem(220);
  height: rem(220);
  position: relative;

  &.hide-background {
    background: none;

    .loading-spinner {
      path {
        fill: $sheetz-green;
      }
    }
  }

  .loading-icon {
    position: absolute;
    top: 0;
    left: 0;

    > * {
      width: 100%;
    }
  }

  .loading-spinner {
    animation: rotation 3s infinite linear;
  }
}
