@use "assets/styles" as *;

div.receipt-container {
  padding-bottom: rem(20);

  div.section-container {
    padding: rem(20);
    border-bottom: $default-border;
  }

  div.order-items-display {

    div.total-savings-bar-container {
      margin-top: rem(30);
    }

    div.my-order-label {
      padding: rem(20) 0;
      color: $secondary-medium-gray;
      font-size: rem(14);
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  div.points-earned-container {
    margin-top: rem(34);
  }

  div.cancel-order-container {
    padding: rem(30) rem(10) rem(10);
  }
}
