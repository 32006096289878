@use "assets/styles" as *;

div.find-a-sheetz-container {
  position: relative;
  display: grid;
  height: calc(100vh - #{$header-height});
  max-height: calc(100vh - #{$header-height});
  margin-top: $header-height;

  @media all and (min-width: 768px) {
    grid-template-columns: 35% 65%;
  }

  div.search-filter-container {
    height: rem(170);

    @media all and (min-width: 768px) {
      height: calc(100vh - #{$header-height});
    }

    @include mobile {
      width: 100%;
    }

    .store-search-button {
      padding: 0 rem(20) 0;
      margin-bottom: 0;
      height: rem(77);
    }

    .button-row {
      display: flex;
      align-items: center;

      @media all and (min-width: 768px) {
        margin-bottom: rem(20);
      }

      .state-filter {
        width: 100%;
      }

      .map-filter {
        display: flex;
        justify-content: flex-end;
        min-width: rem(140);

        .map-filter-button {
          height: rem(58);
          border-width: rem(3);
          margin-left: rem(15);
          width: 80%;
          max-width: rem(200);
          display: flex;
          justify-content: center;
          align-items: center;

          .text-left-short {
            float: none;
            padding: 0 rem(6) 0 0;
          }

          .text-right {
            float: none;
            padding: 0;
          }

          svg {
            width: rem(26);
            transform: scaleX(-1);
            vertical-align: middle;
            path.cls-1 {
              fill: $primary-dark-gray;
            }
          }
        }
      }
    }
  }

  div.map-container {
    height: rem(350);
    border-top: $default-border;

    @media all and (min-width: 768px) {
      height: 100%;
      border-top: none;
    }

    .store-pin-icon-marker {
      background-image: url(~assets/icons/icon_store_pin.svg);
    }

    .store-cluster-icon-marker {
      background-image: url(~assets/icons/icon_store_cluster.svg);
    }

    svg {
      width: rem(15);
    }

    .mapboxgl-marker {
      width: rem(40);
      height: rem(40);

      span.store-cluster-count {
        z-index: $find-a-sheetz-map-index;
        color: $white;
        font-size: rem(12);
        display: inline-block;
        width: rem(40);
        height: rem(40);
        text-align: center;
        padding-top: rem(13);
        font-weight: bold;
      }
    }

    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
      z-index: 1;
    }

    div.my-location-container {
      position: absolute;
      right: rem(25);
      bottom: rem(25);
      z-index: $find-a-sheetz-map-index;

      .my-location-button {
        border-radius: rem(50);
        height: rem(50);
        width: rem(50);
        background-color: $sheetz-blue;
        color: $white;
        border: rem(2) solid $sheetz-blue;

        &:active,
        &:hover {
          background-color: darken($sheetz-blue, 5%);
          border: rem(2) solid darken($sheetz-blue, 5%);
        }
        svg.location-icon {
          width: rem(18);
          fill: $white;
        }
      }
    }
  }

  div.store-lists-container {
    height: calc(100vh - (#{$header-height} + #{rem(137)}));
    background-color: $white;
    border-top: $default-border;
    padding-top: rem(20);

    @media all and (min-width: 768px) {
      overflow-y: scroll;
    }

    div.filter-buttons-container {
      padding: 0 rem(17) rem(20) rem(17);

      button.store-filter-button {
        height: rem(30);
        font-size: rem(12);
        border-color: $border;
        margin: rem(3);

        svg {
          height: rem(7);
          .x_icon_1 {
            fill: $border;
          }
        }

        span {
          line-height: normal;
        }

        span.text-left {
          padding-right: rem(10);
          margin-left: rem(8);
        }
      }
    }

    div.results-container {
      > p {
        color: $tertiary-gray;
        font-size: rem(14);
        font-weight: bold;
        padding-left: rem(20);
      }

      ul.store-list-container {
        list-style-type: none;
        padding: 0;
        margin-top: 0;

        .list-item-icon-container {
          padding-right: 0;
        }
      }
    }

    .empty-closest-stores-container {
      .whats-nearby-label {
        font-weight: bold;
        color: $secondary-medium-gray;
        text-align: center;
        padding-top: rem(20);
      }

      .store-no-results-icon {
        padding-top: rem(35);
        text-align: center;

        svg {
          width: rem(30);
        }
      }

      .empty-closest-stores-body {
        font-size: rem(12);
        color: $tertiary-gray;
        text-align: center;
        padding: rem(15) 0 rem(20) 0;

        @include mobile {
          max-width: rem(300);
          margin: auto;
        }
      }
    }
  }
}
