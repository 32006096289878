@use "assets/styles" as *;

svg.footerBackground {
  position: fixed;
  width: 100%;
  height: rem(414);
  top: calc(100% - 160px);
  z-index: $homepage-footer-bg-index;
  path {
    fill: $primary-dark-gray;
  }
}

div.footer {
  position: fixed;
  width: 100%;
  height: rem(78);
  bottom: 0;
  .footerBar {
    position: absolute;
    width: 100%;
    text-align: center;
    .orderButton {
      z-index: $homepage-footer-order-button-index;
      margin: rem(10) rem(5) rem(20) rem(5);
      width: 45%;
      min-width: rem(135); //45% of rem(300)
      max-width: rem(270); //45% of rem(600)
    }
  }
}

div.store-header-container {
  opacity: 0;
  &.show {
    opacity: 1;
  }
  transition: opacity 0.3s;
  div.store-header {
    z-index: $action-sheet-content-index;
    border: rem(2) solid $white;
    box-shadow: 0 0 rem(14) rgba(255, 255, 255, 0.7);
  }
}
