@use "assets/styles" as *;

div.sheetz-header {
  position: fixed;
  top: 0;
  z-index: $header-index;
  background: url(../../../assets/swoops/header_swoop.svg) bottom center no-repeat;
  background-size: 100% 150%;
  width: 100vw;
  height: $header-height;

  &.standard {
    position: relative;
    background: $sheetz-red;
    background-size: initial;

    &.fixed {
      position: fixed;
      z-index: $header-index;
    }

    .menu-button {
      height: rem(70);
    }
  }

  &.header-green {
    background: $pay-green;
  }

  div.header-content {
    display: flex;
    align-items: flex-start;
    height: 100%;

    > * {
      margin: 0 auto;
      @include desktop {
        flex: 1;
      }
    }

    .left-content,
    .right-content {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .right-content {
      justify-content: flex-end;
    }

    // Styles for mobile/desktop order header
    &.order {
      @include mobile {
        div.bread-crumb {
          display: none;
        }
        .menu-button {
          p {
            display: none;
          }
        }
        div.order-title {
          display: none;
        }
        div.store-content-container {
          display: none;
        }
        div.bag-button {
          display: none;
        }
      }
      @include desktop {
        > * {
          margin: 0;
          align-self: center;
          color: $white;
        }
        .nav-button {
          display: none;
        }
        .menu-button {
          width: rem(80);
          padding: 0 rem(26);
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: rem(10);
            font-weight: bold;
          }
        }
        div.bread-crumb {
          button {
            @include unstyled-button;
          }
        }
        div.page-title {
          display: none;
        }
        div.order-title {
          @include obviously-narrow;
          font-style: italic;
          font-size: rem(32);
          transform: rotate(-4deg);
          @include desktop {
            flex: 0 0 auto;
          }
        }
        div.delivery-pickup-sub-header-container {
          display: flex;
          flex-direction: row-reverse;
          padding-right: rem(26);

          &.show-shopping-bag {
            padding-right: rem(136);
          }
          .store-icon {
            width: rem(32);
            float: left;
            path {
              fill: $white;
            }
            margin-right: rem(16);
          }
        }
      }
    }

    div.button-placeholder {
      width: rem(60);
      height: 100%;
    }

    div.page-title,
    div.logo-container {
      flex: 1;
      align-self: flex-end;
      padding-bottom: rem(12);
      text-align: center;
      color: $white;
      font-size: rem(14);
      font-weight: bold;
    }

    div.page-title {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
    }

    div.logo-container {
      padding: 0;
    }

    .nav-button,
    .login-button,
    .user-button,
    .menu-button,
    .close-button {
      color: $white;
      background: transparent;
      border: none;
      width: rem(60);

      svg path {
        fill: $white;
      }
    }

    .nav-button {
      padding: rem(14);
    }

    .menu-button {
      padding: rem(10);
    }

    &.homepage .menu-button {
      margin-right: rem(34);
      width: rem(50);
      padding: rem(14);
    }

    .nav-button,
    .menu-button {
      width: rem(42);
      align-self: center;
    }

    .order-login-button {
      align-self: center;
    }

    .login-button {
      align-self: center;
      margin-left: rem(24);
    }

    .user-button {
      align-self: center;
      padding: rem(16) rem(16) rem(16) rem(10);
      margin-left: rem(24);

      &.desktop-user-button {
        margin-right: rem(10);
      }
    }

    .close-button {
      padding: rem(28) rem(23);
      height: rem(70);
    }

    .sheetz-logo {
      width: 60%;
      max-width: rem(225);
      vertical-align: bottom;

      path.cls-2 {
        fill: $white;
      }
    }
  }
}
