@use "assets/styles" as *;

div.account-main-content {
  position: relative;
  margin-top: $header-height;
  height: calc(100vh - #{$header-height});

  &.in-mobile-app {
    margin-top: 0;
    height: 100vh;
  }

  &.account-settings {
    background-color: $off-white;
  }
}
