@use "assets/styles" as *;

div.item-display {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: rem(20) 0;
  border-bottom: $default-border;

  @include desktop() {
    grid-template-columns: 15% 70% 15%;
  }

  &.side-padding {
    padding: rem(20);
  }

  p.lg-body {
    @include lg-body;
    color: $primary-dark-gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
  }

  p.sm-body {
    @include sm-body;
    color: $secondary-medium-gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-align: left;
  }

  div.item-image,
  div.item-text {
    width: auto;
  }

  div.item-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    p.lg-body {
      margin-bottom: rem(8);
    }
  }

  div.item-discount {
    grid-column: 1 / -1;
    margin-top: rem(12);
  }

  div.item-display-footer {
    align-items: center;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 20% 40% 40%;
    margin-top: rem(16);

    @include desktop() {
      grid-template-columns: 15% 45% 40%;
    }

    .edit-item {
      padding-left: rem(18);
    }

    button {
      padding: 0;
    }
  }
}
