@use "assets/styles" as *;

div.add-funds-body {
  // This calculation accounts for the footer height, header and then the additional header section that was added for this modal.
  height: calc(100% - #{$payment-footer-height} - #{$header-height} * 1.3);
  overflow-y: scroll;

  .purchase-details-label {
    padding-top: rem(20);
    @include desktop {
      padding-top: rem(50);
    }
    padding-left: rem(30);
    text-align: left;
    color: $tertiary-gray;
    font-size: rem(16);
    font-weight: bold;
  }
  .purchase-details-row {
    padding-top: rem(30);
    @include desktop {
      padding-top: rem(40);
    }
    display: flex;
    color: $primary-dark-gray;
    font-weight: bold;
    font-size: rem(20);
    .purchase-amount-label {
      @include desktop {
        padding-bottom: rem(30);
        margin-left: rem(50);
      }
      white-space: nowrap;
      margin-left: rem(30);
      padding-left: rem(10);
      padding-bottom: rem(30);
      text-align: left;
      flex: 1;
      border-bottom: $default-border;
    }
    .purchase-amount {
      flex: 1;
      text-align: right;
      margin-right: rem(30);
      padding-bottom: rem(20);
      @include desktop {
        padding-bottom: rem(30);
        margin-right: rem(50);
      }
      padding-right: rem(10);
      border-bottom: $default-border;
    }
  }
}
