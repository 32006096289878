@use "assets/styles/mixins" as *;
@use "assets/styles/functions" as *;

@mixin xxl-body {
  @include roboto;
  font-size: rem(22);
  line-height: rem(30);
  font-weight: bold;
}

@mixin xl-body {
  @include roboto;
  font-size: rem(18);
  line-height: rem(26);
  font-weight: bold;
}

@mixin lg-body {
  @include roboto;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: bold;
}

@mixin reg-body {
  @include roboto;
  font-size: rem(14);
  line-height: rem(22);
  font-weight: normal;
}

@mixin sm-body {
  @include roboto;
  font-size: rem(12);
  line-height: rem(16);
  font-weight: normal;
}

@mixin tiny-body {
  @include roboto;
  font-size: rem(10);
  line-height: rem(12);
  font-weight: normal;
}
