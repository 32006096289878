@use "assets/styles" as *;

div.tab-container {
  height: 100%;
  @include desktop {
    display: grid;
    grid-template-columns: rem(250) auto;
    height: calc(100vh - #{$header-height});
  }

  div.tab-bar {
    border-bottom: rem(1) solid $page-divider;
    border-top: none;
    border-left: none;
    border-right: none;
    height: rem(60);
    margin-top: rem(10);

    @include desktop {
      height: 100%;
      border-right: rem(10) solid $page-divider !important;
      border-bottom: none;
      background-color: $white;
      margin-top: 0;
    }

    div.tab-list {
      display: flex;
      @include desktop {
        padding-top: 30vh;
        display: block;
      }

      button.tab-button {
        flex-grow: 1;
        border: none !important;
        outline: none !important;
        color: $secondary-medium-gray;
        background-color: $white;
        height: rem(60);
        font-weight: bold;
        border-bottom: rem(3) solid $page-divider !important;

        @include desktop {
          display: block;
          text-align: right;
          width: 100%;
          vertical-align: middle;
          border-top: none;
          border-bottom: rem(1) solid $page-divider !important;
          padding-right: rem(20);
          &:first-child {
            border-top: rem(1) solid $page-divider !important;
          }
        }

        &.active-tab {
          border-bottom: rem(3) solid red !important;
          @include desktop {
            border-right: rem(3) solid red !important;
            border-bottom: rem(1) solid $page-divider !important;
            border-top: none;
          }
        }
      }

      button.view-menu-button {
        margin: rem(20);
        width: 85%;
      }
    }
  }
  div.tab-content {
    overflow: hidden auto;
    @include mobile {
      height: calc(100% - #{$tab-height});
    }
    @include desktop {
      height: 100%;
    }
  }
}
