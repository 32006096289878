@use "assets/styles" as *;

div.curbside-details {
  line-height: rem(20);
  font-size: rem(12);
  font-weight: bold;
  display: flex;
  align-items: center;

  div.icon-container {
    display: flex;
    justify-content: center;
    width: rem(40);

    span.curbside-vehicle-icon {
      svg {
        width: rem(34);
        height: rem(34);
        border-radius: rem(10);
      }
    }

    div.alert {
      background-color: $error-orange;
      width: rem(34);
      height: rem(34);
      border-radius: rem(10);
      padding: rem(6);

      .alert-icon {
        margin-top: rem(-1);

        path {
          fill: $white;
        }
      }
    }
  }

  div.curbside-details-container {
    margin-left: rem(12);

    p.details-sub-heading {
      font-size: rem(11);
      font-weight: normal;
    }
  }
}
