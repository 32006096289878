@use "assets/styles" as *;

.add-mysheetzcard {
  .add-mysheetzcard-form {
    padding: rem(20);

    .preferred-row {
      margin: rem(20) 0;
      text-align: right;

      label {
        margin-right: rem(20);
      }

      input {
        background-color: transparent;
        margin: rem(5);
      }
    }

    .button-row {
      height: rem(48);
      display: flex;
      align-items: center;
      button {
        flex-grow: 1;
      }
    }
  }

  .delete-row {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    margin-bottom: rem(20);
  }

  @media only screen and (max-height: rem(600)) {
    height: rem(500);
    overflow: scroll;
    .delete-row {
      position: unset;
    }
  }
}
