@use "assets/styles" as *;

button.sheetz-button {
  @include lg-body;
  @include obviously-narrow;
  height: rem(48);
  padding: 0;
  border-radius: rem(40);
  transition: opacity 0.3s;
  border: none;
  letter-spacing: 1px;

  &.dark-border,
  &.light-border {
    border: 2px solid $primary-dark-gray;
    transition: all 0.125s ease-out;

    &:hover {
      box-shadow: 0 0 0 2px $primary-dark-gray;
    }
  }

  &.light-border {
    border-color: $white;

    &:hover {
      box-shadow: 0 0 0 2px $white;
    }
  }

  &.small {
    height: rem(40);
    border-radius: rem(32);
  }

  &.disabled {
    opacity: 0.4;
  }

  span.text-left {
    float: left;
    margin-left: rem(34);

    svg {
      width: rem(14);
    }
  }

  &.small span.text-left {
    margin-left: rem(26);
  }

  span.text-left-short {
    float: left;
    margin-left: rem(5);
  }

  span.text-right {
    float: right;
    margin-right: rem(34);

    svg {
      width: rem(14);
    }
  }

  &.small span.text-right {
    margin-right: rem(26);
  }

  span.text-center {
    margin: 0 rem(34);
  }

  &.small span.text-center {
    margin: 0 rem(26);
  }

  span.text-left,
  span.text-right,
  span.text-center {
    display: block;
    margin-top: rem(-4) !important;
  }

  /* ------ Button colors ------ */
  &.white {
    color: $primary-dark-gray;
    background-color: $white;
  }

  &.sheetz-red {
    color: $white;
    background-color: $sheetz-red;

    &:active,
    &:hover {
      background-color: $sheetz-red-highlight;
    }
  }

  &.pay-green {
    color: $white;
    background-color: $pay-green;

    &:active,
    &:hover {
      background-color: $pay-green-highlight;
    }
  }

  &.transparent-dark {
    background-color: transparent;
    border: rem(2) solid $primary-dark-gray;
    color: $primary-dark-gray;

    &:active,
    &:hover {
      background-color: transparent;
      box-shadow: 0 0 0 2px $primary-dark-gray;
    }
  }

  &.transparent-light {
    background-color: transparent;
    border: rem(2) solid $white;
    color: $white;

    &:hover {
      background-color: transparent;
      box-shadow: 0 0 0 2px $white;
    }
  }
}
