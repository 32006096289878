@use "assets/styles" as *;

div.header.standard {
  z-index: $auto-index;
}

.modal-close {
  display: flex !important;
}

.gift-card-action-sheet-label {
  color: $primary-dark-gray;
  font-weight: bold;
  padding-top: rem(25);
}

.user-action-sheet-decline-btn-wrapper {
  width: 40%;
  height: rem(30);
  padding-right: rem(25);
  display: inline-block;
}

.user-action-sheet-decline-btn {
  width: rem(130);
  height: rem(30);
  padding-right: rem(25);
  color: $tertiary-gray;
  background-color: $white;
  border: none;
  font-weight: bold;
}

.gift-card-action-sheet-buttons {
  display: flex;
  padding-top: rem(25);
  .gift-card-action-sheet-decline-btn {
    button {
      flex-grow: 1;
    }
    width: rem(200);
    height: rem(30);
    margin: auto;
    color: $tertiary-gray;
    background-color: $white;
    border: none;
  }
}

.gift-card {
  .gift-card-form {
    padding: rem(20);
    width: 100%;
    top: rem(70);
    bottom: rem(25);

    .gift-card-input-container {
      width: 40%;
      float: left;
    }

    .gift-card-placeholder {
      width: 60%;
      float: right;
      font-size: rem(12);
      padding-top: rem(35);
      margin-bottom: rem(20);
      border-bottom: $default-border;
      text-align: right;
    }

    .gift-card-label-invalid {
      border-color: $error-orange !important;
      color: $error-orange !important;
    }

    .button-row {
      height: rem(48);
      display: flex;
      align-items: center;
      margin-bottom: rem(12);

      button {
        flex-grow: 1;
        margin-top: rem(40);
      }
    }

    .default-payment-row {
      padding-bottom: rem(20);
      text-align: left;
      border-bottom: $default-border;

      .default-payment-title {
        font-weight: bold;
        margin-bottom: rem(10);
      }

      .default-payment-text {
        @include sm-body;
        text-align: left;
        color: $tertiary-gray;
        padding-bottom: rem(10);
      }

      &.auto-reload-container {
        padding-top: rem(20);

        div.auto-reload-button-row {
          margin: rem(10) auto rem(20);
          text-align: center;
        }
      }
    }

    .add-funds-button {
      padding-bottom: rem(50);
    }

    .save-button {
      margin-bottom: rem(40);
    }

    .flex-row {
      display: flex;
    }

    .delete-gift-card-button-container {
      padding-top: rem(100);
      padding-bottom: rem(25);
    }

    .delete-gift-card-button-label {
      text-align: center;
      flex: 1;
    }

    .delete-gift-card-button {
      margin-left: auto;
      margin-right: auto;
      padding: 0 rem(24);
      background-color: #ffffff;
      border: 0.125rem solid #343434;
      color: #343434;
      border-radius: 3.125rem;
      height: rem(48);
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-height: rem(600)) {
    height: rem(500);
  }
}
