@use "assets/styles" as *;
div.ordering-preferences-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  div.ordering-preferences-link {
    display: flex;
    align-items: center;

    svg.preference-icon {
      height: rem(32);
      margin-right: rem(20);

      path {
        fill: $secondary-medium-gray;
      }
    }
  }

}

