@use "assets/styles" as *;

.fund-amount-container {
  padding: rem(0) rem(20) rem(20);
  @include desktop {
    max-width: rem(600);
    margin: auto;
  }

  .next-button {
    padding-top: rem(30);
    padding-bottom: rem(50);
  }

  .button-row {
    height: rem(48);
    display: flex;
    align-items: center;

    button {
      flex-grow: 1;
      margin-top: rem(40);
    }
  }

  .add-payment-method-container {
    padding-bottom: rem(70);
    padding-top: rem(30);
    border-bottom: $default-border;
    border-top: $default-border;
  }
}
