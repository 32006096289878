@use "assets/styles" as *;

div.confirm-gift-card-reload-container {
  height: calc(100% - #{$header-height});
  .confirm-gift-card-reload-container-header {
    .header-row {
      display: block;
      padding-top: rem(30);
    }
    background-color: $pay-green;
    color: $white;

    .amount-header {
      @include obviously-narrow;
      font-size: rem(60);
      padding-bottom: rem(30);
    }
  }

  div.confirm-gift-card-reload-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
