@use "assets/styles" as *;

div.discount-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $body-bg;
  height: rem(54);
  padding: 0 rem(16);
  border-radius: rem(14);

  div.discount-left {
    display: flex;

    div.discount-icon {
      width: rem(18);
      height: rem(18);

      svg path {
        fill: $sheetz-red;
      }
    }

    div.discount-text p {
      @include reg-body();
      color: $primary-dark-gray;
      margin-left: rem(12);
    }
  }

  div.discount-price p {
    @include sm-body();
    color: $sheetz-red;
  }
}
