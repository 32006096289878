@use "assets/styles" as *;

$shopping-bag-store-button-height: rem(90);

// Mobile version of bag button
@include mobile() {
  button.shopping-bag-button {
    @include unstyled-button();
    background-color: $sheetz-green;

    svg.shopping-bag-button-icon {
      height: rem(48);
      width: rem(48);
      margin: rem(8);

      path {
        fill: $white;
      }
    }
    position: fixed;
    height: rem(64);
    width: rem(64);
    bottom: rem(20);
    right: rem(20);
    border-radius: rem(32);

    div.shopping-bag-quantity {
      position: absolute;
      right: 0;
      background-color: $sheetz-red;
      height: rem(18);
      width: rem(18);
      border-radius: rem(8);
      color: $white;
      text-align: center;
      padding-top: rem(3);
      font-size: rem(12);
    }
  }

  div.order-main-content.in-mobile-app button.shopping-bag-button {
    bottom: rem(52);
  }
}

// Desktop version of bag button
@include desktop() {
  button.shopping-bag-button {
    @include unstyled-button();
    z-index: $shopping-bag-button-index;
    background-color: $sheetz-green;
    background: url(~assets/swoops/bag_swoop.svg) bottom left no-repeat;
    background-size: cover;
    height: rem(70);
    width: rem(120);
    svg.shopping-bag-button-icon {
      height: rem(36);
      width: rem(36);
      path {
        fill: $white;
      }
    }

    div.shopping-bag-quantity {
      position: absolute;
      right: rem(20);
      top: rem(24);
      color: $white;
      font-size: rem(18);
      font-weight: bold;
    }
  }
}

// Shopping Bag styling
div.shopping-bag {
  z-index: $shopping-bag-index !important;
  background-color: $white;
  top: 0;
  padding-bottom: rem(78);

  &.in-mobile-app {
    padding-bottom: rem(110);
  }

  &.empty {
    padding-bottom: 0;
  }

  button.shopping-bag-store-button {
    @include unstyled-button;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(28) !important;
    height: $shopping-bag-store-button-height;
    width: 100%;
    background-color: $primary-dark-gray;

    div.shopping-bag-store-details {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div.shopping-bag-store-label {
        padding-left: rem(10);

        p.address {
          margin-top: rem(4);
        }
      }
      svg.store-icon,
      svg.home-icon {
        height: rem(32);
        path {
          fill: $white;
        }
      }
    }

    svg.icon-more-dots {
      width: rem(4);
    }
  }
  div.continue-ordering-container {
    padding: rem(15);
    &.empty {
      position: relative;
      padding-top: 30%;
      height: calc(100vh - #{$shopping-bag-store-button-height});
    }
    div.empty-bag-container {
      text-align: center;
      svg.empty-bag-icon {
        width: 50%;
      }
      div.empty-bag-message {
        padding-top: rem(10);
        @include obviously-narrow;

        div.empty-bag-title {
          font-size: rem(30);
          text-transform: uppercase;
          color: $primary-dark-gray;
        }
        div.empty-bag-subtext {
          padding: rem(25) 0;
          font-size: rem(18);
          color: $secondary-medium-gray;
        }
      }
    }
    button.continue-ordering-button {
      width: 100%;
    }
    p.version {
      padding-top: rem(225);
      font-size: rem(11);
      text-align: center;
    }
  }
  div.shopping-bag-checkout-container {
    background-color: $primary-dark-gray;
    height: rem(78);
    padding: rem(15);
    position: absolute;
    width: 100%;
    bottom: 0;

    button.shopping-bag-checkout-button {
      width: 100%;
    }
  }
}

div.shopping-bag-overlay {
  z-index: $shopping-bag-overlay-index !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

div.shopping-bag.in-mobile-app .shopping-bag-checkout-container {
  height: rem(110);
}
