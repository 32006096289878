@use "assets/styles" as *;

button.customization-option {
  @include unstyled-button;
  color: $primary-dark-gray;
  width: 100%;
  height: 100%;
  border-radius: rem(10);
  border: $default-border;
  position: relative;
  background-color: $white;

  .customization-check-icon {
    position: absolute;
    width: 15%;
    top: 15%;
    right: -7%;
  }

  &.selected {
    border-color: $sheetz-red;
    &.green-border {
      border-color: $sheetz-green;
      svg .customization-check-circle {
        fill: $sheetz-green;
      }
    }
  }

  [data-whatintent="keyboard"] &:focus {
    outline-color: none;
    border-color: $primary-dark-gray;
    background-color: $white;
  }

  div.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    img {
      width: 100%;
    }

    svg {
      width: 50%;
    }

    p.option-name {
      font-weight: bold;
      margin-bottom: rem(10);
      padding: 0 rem(6);
    }

    p.option-descriptive-text {
      @include tiny-body;
      padding: 0 rem(6);
      margin-bottom: rem(10);
    }

    div.details {
      height: rem(24);
      margin-top: rem(10);
      display: flex;
      justify-content: space-around;

      span.price {
        margin-left: rem(8);
        font-size: rem(10);
        color: $sheetz-green;
        line-height: rem(10);
      }
      span.nutrition {
        margin-right: rem(8);
        font-size: rem(10);
        color: $tertiary-gray;
        line-height: rem(10);
      }
    }

    div.portioning-button {
      height: rem(24);
      text-align: center;
      background-color: $sheetz-red;
      border-radius: rem(12);
      color: $white;
      font-size: rem(10);
      padding: rem(6) 0;
      margin: rem(6);
    }
  }

  &.condiment {
    p.option-name {
      font-size: rem(12);
    }
  }
}

// Style overrides for options contained within an action sheet grid.
.action-sheet-grid button.customization-option div.flex-container {
  align-items: center;

  svg {
    margin: rem(16) 0;
  }

  div.details {
    width: 100%;
  }
}

// Styling for options that are unable to be selected due to the maximum number of selections being reached.
.limit-reached > .condiment-grid > button.customization-option:not(.selected) {
  opacity: 0.5;
}

.action-sheet button.customization-option div.flex-container {
  img {
    width: 60%;
    height: auto;
  }
}
