@use "assets/styles" as *;

div.restricted-item-pickup-terms {
  p {
    @include sm-body;
    text-align: left;
  }

  div.instructions {
    margin-bottom: rem(16);

    p.instruction {
      margin-left: rem(16);
    }
  }

  p.disclaimer {
    margin: rem(24) 0 rem(16);
  }

  button {
    width: 100%;
  }
}
