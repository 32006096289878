@use "assets/styles" as *;

.sideMenu {
  .bm-menu {
    background-color: $white;
  }

  p {
    margin: 0;
  }

  header {
    /* !important required due to forced inline styling on menu children. */
    display: flex !important;
    background-color: $primary-dark-gray;
    color: $white;
    padding: rem(24) rem(16) rem(52) rem(24);

    :first-child {
      flex: 1;
    }

    p {
      font-weight: bold;
      &.tier-label {
        margin-top: rem(4);
        font-size: rem(12);
      }
    }
  }

  ul.account-menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;

    div.sidebar-list-item-flex-container {
      display: flex;
      padding-left: rem(30);
      border-bottom: $default-border;

      &:last-child {
        border-bottom: none;
      }

      > * {
        margin: auto;
      }

      div.menu-item-icon-container {
        padding: rem(6) 0;

        svg.menu-item-icon {
          width: rem(25);
          height: rem(25);
          path {
            fill: $secondary-medium-gray;
          }
        }
      }

      p.menu-item-label {
        flex: 1;
        padding-left: rem(24);
      }

      p.menu-item-label,
      p.spendable-points-value {
        color: $primary-dark-gray;
        font-size: rem(16);
        font-weight: bold;
      }

      div.spendable-points-container {
        text-align: right;
        padding-right: rem(10);

        p.spendable-points-label {
          color: black;
          font-size: rem(12);
        }
      }
    }
  }
}
