@use "assets/styles" as *;

div.verify-age-container {
  background-color: $white;


  div.verify-your-age-form-container {
    padding: rem(50) rem(20);

    div.step-container {
      padding-bottom: rem(20);
      display: flex;

      div.step-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $sheetz-green;
        width: rem(40);
        height: rem(40);
        min-width: rem(40);

        p.step-number {
          @include xl-body;
          color: $white;
        }
      }

      .step-instruction-label {
        font-weight: bold;
        padding-left: rem(15);
        line-height: 150%;
      }

      .center-label {
        margin: auto 0;
      }
    }

    div.birthdate-input {
      padding-bottom: rem(30);
    }

    button {
      display: block;
      width: 100%;
      max-width: rem(500);
      margin: rem(10) auto 0;
    }
  }
}
