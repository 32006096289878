@use "assets/styles" as *;

div.item-image-text-container {
  display: flex;
  flex: 1;
}

div.item-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;

  img {
    width: 90%;

    &.static-image {
      width: rem(45);
    }
  }

  @include desktop {
    width: 15%;
  }
}

div.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: rem(10);
  width: 80%;

  @include desktop {
    width: 85%;
  }

  &.padding-right {
    padding-right: rem(12);
  }

  p.item-title {
    @include lg-body;
    color: $primary-dark-gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;

    &.margin-bottom {
      margin-bottom: rem(4);
    }
  }

  p.item-description {
    @include sm-body;
    color: $secondary-medium-gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-align: left;
  }

}

