@use "assets/styles" as *;

.funds-amount-header-container {
  padding: rem(20);
  background-color: $pay-green;
  color: $white;
  .flex-row {
    display: flex;
    padding: rem(20);
    align-items: center;
    .fund-amount-input {
      @include obviously-narrow;
      color: $white;
      background-color: $pay-green;
      border: none;
      font-size: rem(55);
      width: 55%;
      text-align: right;
      @include desktop {
        width: 50%;
      }
    }
    .funds-amount-header-label {
      padding-left: rem(10);
      width: 50%;
      font-size: rem(14);
    }
  }
}
