@use "assets/styles" as *;

div.store-selection {
  @include order-config-sidebar-padding;

  div.store-lists-container {
    padding-top: rem(20);
    position: relative;
    overflow-y: scroll;
    background-color: $white;
    min-height: rem(250);

    @include desktop {
      overflow-y: auto;
    }

    div.results-container {
      > p {
        color: $tertiary-gray;
        font-size: rem(14);
        font-weight: bold;
        padding-left: rem(20);
      }

      ul.store-list-container {
        list-style-type: none;
        padding: 0;
        margin-top: 0;
      }
    }

    .empty-closest-stores-container {
      @include desktop {
        height: rem(500);
        width: 100%;
      }
      .whats-nearby-label {
        font-weight: bold;
        color: $secondary-medium-gray;
        text-align: center;
        padding-top: rem(20);
      }
      .store-no-results-icon {
        padding-top: rem(35);
        text-align: center;
        svg {
          width: rem(30);
        }
      }
      .empty-closest-stores-body {
        font-size: rem(12);
        color: $tertiary-gray;
        text-align: center;
        padding: rem(15) 0 rem(20) 0;
        @include mobile {
          max-width: rem(300);
          margin: auto;
        }
      }
    }
  }
}
