@use "sass:math";
@use "assets/styles" as *;

div.edit-combo-container {
  padding: rem(20);

  p.edit-combo-title {
    @include obviously-narrow;
    font-size: rem(24);
    margin-bottom: rem(24);
    text-align: left;
  }

  div.combo-list-items {
    list-style-type: none;
    margin-bottom: rem(48);
    padding: 0;
    text-align: left;

    div.combo-list-item {
      padding: rem(20) 0;
      border-bottom: $default-border;

      button {
        margin: rem(16) 0 0 20%;
        padding-left: rem(10);
      }
    }
  }

  .edit-combo-done-button {
    width: 100%;
  }
}
