@use "assets/styles" as *;

div.register-form {
  .submit-button {
    width: 100%;
    max-width: rem(400);
    margin: rem(10) 0;
  }
  .row {
    margin-bottom: rem(10);
  }
  div.forgot-password {
    margin-top: rem(16);
    a {
      color: black;
    }
  }
  div.agreement {
    color: $tertiary-gray;
    font-size: rem(10);
    margin: rem(10) 0;
  }
  div.only-key-ring {
    float: right;
    margin: rem(10) 0;
    button {
      border: none;
      background: none;
      text-decoration: underline;
    }
  }
}
.key-ring-dismiss-button {
  width: 100%;
  max-width: rem(400);
  margin: rem(30) 0 rem(20) 0;
}
