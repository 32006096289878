@use "assets/styles" as *;

div.my-stores-container {
  position: relative;
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  &.empty {
    padding: rem(150) 0 rem(100);
  }

  div.store-container {
    display: flex;
    justify-content: space-between;

    svg.trash-icon {
      height: rem(24);
      margin: rem(20) rem(10) rem(20) rem(10);
      path.trash_icon_1 {
        fill: $tertiary-gray;
      }
    }
  }
}



