@use "assets/styles" as *;

div.order-main-content.in-mobile-app div.menu div.featured-content-container {
  @include mobile {
    top: 0;
  }
}

div.menu {
  @include desktop {
    display: flex;
    // Height is the viewport minus the header size.
    height: calc(100vh - #{$header-height});
  }

  div.featured-content-container {
    @include desktop {
      padding-right: rem(10);
      background-color: $page-divider;
    }

    @include mobile {
      position: sticky;
      top: $header-height;
    }

    &.fixed-at-top {
      @include mobile {
        z-index: 1;
      }
    }

    button.sheetz-container-button {
      @include mobile {
        position: relative;
      }
    }

    img.feature-image {
      max-width: 100%;
      height: auto;
    }
  }

  div.menu-content-container {
    position: relative;
    padding-bottom: rem(20);
    background-color: $white;

    @include desktop {
      max-width: rem(400);
      flex: 0 0 35%;
      overflow: auto;
    }

    &.no-featured-item-banner {
      @include desktop-max-width {
        flex: 1 0 100%;
        overflow: initial;
      }
    }

    button.menu-search-button {
      @include unstyled-button;
      margin: rem(5);
      width: 100%;
    }

    ul.child-category-list {
      list-style-type: none;
      margin: 0;
      padding: 0 0 32px;

      @include desktop() {
        padding: 0;
      }

      li {
        a {
          text-decoration: inherit;
          color: inherit;
        }

        div.list-item-container {
          padding-left: rem(8);

          div.child-category-container,
          div.menu-item-container,
          div.combo-container {
            display: flex;
            align-items: center;
            height: rem(65);

            > {
              margin: auto;
            }

            img {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
