@use "assets/styles" as *;

$footer-height: rem(104);

div.reorder-container {
  height: calc(100vh - #{$header-height});

  ul.favorites-list,
  ul.history-list {
    list-style-type: none;
    margin: 0;

    @include desktop() {
      max-width: $desktop-width-rem;
      margin: 10% auto;
    }
    padding: 0;
  }

  ul.favorites-list {
    li {
      display: flex;
    }
  }
  @include desktop {
    height: 100%;
  }

  div.tab-container {
    @include mobile() {
      padding-bottom: $footer-height;
      height: calc(100% - #{$header-height});
    }
  }

  div.history-menu-button {
    position: absolute;
    display: grid;
    grid-template-columns: auto $footer-height;
    bottom: 0;
    height: $footer-height;
    padding: rem(23) 0 rem(23) rem(28);
    width: 100%;
    background-color: $primary-dark-gray;

    &.full-width {
      grid-template-columns: auto;
      padding-right: rem(28);
    }

    button {
      margin-top: rem(6)
    }
  }

  svg.empty-page-icon {
    path {
      fill: black !important;
    }
  }

  div.reorder-empty-page-buttons {
    margin-top: rem(10);
    display: inline-grid;
    > button {
      margin-bottom: rem(10);
    }
  }
}

div.in-mobile-app {
  div.tab-container {
    height: calc(100% - #{rem(32)});
  }

  div.history-menu-button {
    height: calc(#{$footer-height} + #{rem(32)});
  }
}
