@use "assets/styles" as *;

div.sheetz-select {
  position: relative;
  padding: rem(20) 0;

  span.select-arrow {
    position: absolute;
    top: rem(40);
    right: rem(20);
    pointer-events: none;

    svg {
      width: rem(10);
      transform: rotateZ(-90deg);
    }
  }

  &.no-button-style span.select-arrow {
    top: rem(30);

    svg path {
      fill: $tertiary-gray;
    }
  }

  select {
    font-size: rem(16);
    color: $secondary-medium-gray;
    border: none;
    border-bottom: $default-border;
    padding: 0.6em 1.4em 0.6em 0.8em;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.button-style {
      height: rem(58);
      padding: 0 rem(30);
      border: rem(3) solid $primary-dark-gray;
      border-radius: rem(50);
      color: $primary-dark-gray;
      font-weight: bold;
    }
  }

  label {
    position: absolute;
    top: rem(10);
    left: rem(10);
    display: block;
    transition: 0.3s;
    font-size: rem(12);
    color: $secondary-medium-gray;
  }

  &.error {
    select {
      border-color: $error-orange !important;
      color: $error-orange !important;
    }
    label {
      color: $error-orange;
    }
    div.error {
      font-size: rem(12);
      position: absolute;
      left: rem(10);
      bottom: 0;
      color: $error-orange;
    }
  }
}
