@use "assets/styles" as *;

div.my-information-container {
  background-color: $white;
  padding-top: rem(15);

  @include desktop {
    padding-top: 0;
  }

  p.add-employee-info {
    @include lg-body;
    color: $sheetz-blue;
    text-decoration: underline;
    margin-right: rem(12);
  }

  svg.icon-customization-check {
    height: rem(24);

    .customization-check-circle {
      fill: $sheetz-green;
    }
  }
}
