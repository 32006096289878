@use "assets/styles" as *;

div.age-restricted-offers-container {
  display: flex;
  flex-direction: column;
  background-color: $border;
  min-height: 100%;

  div.opted-out-empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    margin-top: rem(-80);

    &.mobile-margin {
      margin-top: rem(80);
    }

    p {
      @include lg-body;
      margin-top: rem(20);

      .link {
        color: $primary-dark-gray;
        padding: 0;
      }
    }
  }

  div.bybe-icon-container {
    @include tiny-body;
    padding: 0 rem(10) 0 rem(20);

    svg.bybe-icon {
      width: rem(40);
      margin-left: rem(5);
    }
  }

  div.filter-container {
    margin: 0 auto;
    padding: 0 rem(20);
    width: 100%;
    max-width: rem(700);

    div.filtername-label {
      font-weight: bold;
      padding-left: rem(20);
    }

    div.sheetzInputFilter {
      padding: 0 rem(20);
    }
  }

  div.my-offers-container {
    margin-bottom: rem(10);
    column-gap: rem(10);
    row-gap: rem(10);
    padding: rem(20);
    justify-content: space-evenly;
    max-width: rem(500);
    @include desktop {
      margin: 0 auto;
      display: grid;
    }

    div.offername-label {
      font-weight: bold;
      margin-left: rem(10);
      margin-bottom: rem(10);
    }

    div.empty-offername-label {
      font-weight: bold;
      margin-left: rem(10);
      margin-bottom: rem(200);
    }
  }
}
