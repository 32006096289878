@use "assets/styles" as *;

div.store-detail-modal-container {
  text-align: start;
  max-width: rem(600);

  @include desktop {
    margin-right: auto;
    margin-left: auto;
  }

  div.store-detail-map-container {
    position: fixed;
    top: $header-height;
    width: 100vw !important;
    height: rem(290);

    @include desktop {
      position: relative;
      top: 0;
      height: rem(300);
      width: 100% !important;
    }

    .store-pin-icon-marker {
      background-image: url(~assets/icons/icon_store_pin.svg);
    }

    .mapboxgl-marker {
      width: rem(70);
      height: rem(70);
    }
  }

  div.store-details-container {
    position: relative;
    background-color: $white;
    z-index: $find-a-sheetz-map-index;
    padding-top: rem(25);
    border-top: $default-border;
    top: rem(290);

    @include mobile {
      overflow-y: scroll;
    }

    &.no-scroll {
      overflow-y: initial;
    }

    @include desktop {
      top: 0;
    }

    div.store-container {
      display: inline-block;
      padding: 0 rem(20) 0;
      width: 100%;
      height: 100%;
      border-bottom: $default-border;

      @include desktop {
        padding-bottom: rem(40);
      }

      ul.store-list-container {
        list-style-type: none;
        padding: 0;
        margin-top: 0;
      }

      div.save-store-button-flex-container,
      div.save-order-button-flex-container {
        display: flex;
        cursor: pointer;
        align-items: center;

        div.circle {
          height: rem(30);
          width: rem(30);
          border: $default-border;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: rem(16);

          svg {
            width: 60%;
            // Pull the icon up slighty - this seems to center it better in the circle.
            margin-top: rem(-1);

            &.favorite path {
              fill: $white;
            }

            path {
              fill: $border;
            }
          }
        }
        div.favorite {
          background-color: $sheetz-blue;
          border: $sheetz-blue;
        }

        p.label {
          font-weight: bold;
        }
      }

      div.save-store-button-flex-container {
        @include desktop {
          margin: rem(30) 0 0;
        }
      }

      .button-row-container {
        padding-top: rem(15);
        display: flex;

        .go-here-container {
          flex-grow: 1;
          margin-right: rem(5);

          svg {
            margin-left: rem(8);
            margin-top: rem(6);
          }
        }

        .order-here-container {
          flex-grow: 1;
          margin-left: rem(5);
          button {
            width: 100%;
          }
        }
      }

      div.call-this-store-label {
        padding: rem(40) 0;
        text-align: center;
        font-weight: bold;

        svg.phone-icon {
          height: rem(20);
          padding-right: rem(5);
        }

        a {
          color: $primary-dark-gray;
          text-decoration-line: none;
        }
      }
    }

    div.store-attributes-container div.fuel-prices-container {
      background-image: url(../../../../assets/images/fuel_prices_bg.jpg);
      background-size: cover;
      background-position: center;
      margin-bottom: rem(30);
      padding: rem(50) rem(20);

      @include desktop {
        padding: rem(80) rem(20);
      }

      div.fuel-prices-content {
        background-color: rgba(255, 255, 255, 0.8);
        padding: rem(20);
        border-radius: rem(8);

        div.looking-for-fuel-prices-label {
          @include obviously-narrow;
          font-size: rem(22);
          font-weight: bold;
          text-align: center;
        }
        div.fuel-prices-available-label {
          padding: rem(10) 0 rem(20);
          text-align: center;
          font-weight: 500;
        }
        div.get-the-app-row {
          text-align: center;
        }
      }
    }

    div.fuel-types-label {
      text-align: left;
      font-size: rem(16);
      color: $tertiary-gray;
      font-weight: bold;
      padding: 0 0 rem(30) rem(20);
    }

    div.fuel-type-border {
      border-bottom: $default-border;
    }

    div.ev-features,
    div.store-features {
      padding-top: rem(25);
    }

    div.fuel-type-container {
      padding: 0 rem(20) rem(15);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: rem(600);
      row-gap: rem(30);

      div.store-feature-container {
        display: flex;
        align-items: center;

        div.store-feature-icon {
          .ev-type-icon {
            width: rem(40);
          }

          .fuel-type-icon {
            width: rem(40);
          }

          .store-filter-icon {
            width: rem(40);
          }
        }

        div.store-feature-label {
          padding-right: rem(20);
          padding-left: rem(5);
          font-size: rem(14);
          font-weight: 500;
          line-height: 125%;
        }
      }
    }
  }
}
