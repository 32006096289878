@use "assets/styles" as *;

div.total-savings-bar {
  background-color: $sheetz-red;
  padding: 0 rem(16) rem(10);
  z-index: $savings-bar-index;

  &.standalone {
    height: rem(54);
    padding: 0 rem(16);
    margin: 0 rem(20);
    border-radius: rem(14);
    z-index: 0;

    div.total-savings-bar-content {
      height: rem(54);
    }
  }

  .total-savings-bar-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      @include reg-title;
      color: $white;
      margin-top: rem(-4);
    }
  }
}

div.order-totals-container {
  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: rem(16);
    @include lg-body;

    p.amount {
      color: $primary-dark-gray;
      width: rem(104);
      text-align: right;
    }
  }

  p.order-summary {
    @include lg-body;
    color: $primary-dark-gray;
    margin-bottom: rem(20);
  }

  div.discount-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: $body-bg;
    border-radius: rem(14);
    padding: rem(10) 0 rem(8);

    div.discount-display {
      width: 100%;
      height: rem(40);
    }
  }

  div.price-list-container {
    display: flex;
    justify-content: space-between;
  }

  p.amount {
    @include lg-body;
    color: $primary-dark-gray;
  }

  div .label {
    @include reg-body;
    color: $secondary-medium-gray;
  }

  div.label-container {
    .help-icon {
      vertical-align: text-top;
      margin-right: rem(4);
    }
  }

  div.tax-total {
    margin-bottom: rem(12);
  }

  div.order-total {
    margin-bottom: 0;
  }
}

div.order-total-container-margin {
  margin-bottom: rem(64);
}