@use "assets/styles" as *;

div.payment-method-error-manager {
  @include desktop {
    max-width: $desktop-width-rem;
    padding: 0 rem(20);
  }

  div.payment-method-errors {
    margin: rem(10) 0 rem(30);

    div.payment-method-errors-message {
      border: rem(2) solid $error-orange;
      border-radius: rem(12);
      padding: rem(5);

      p {
        @include sm-body;
      }
    }

    button.change-payment {
      width: 100%;
      margin-top: rem(12);
    }
  }
}
