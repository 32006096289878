@use "assets/styles" as *;

div.toast {
  &.success,
  &.confirm {
    background: url(../../../../assets/swoops/down_swoop_success.svg);
  }
  &.error {
    background: url(../../../../assets/swoops/down_swoop_error.svg);
  }

  z-index: $sheetz-toast-index;
  position: fixed;
  top: 0;
  height: auto;
  transition: transform 0.3s ease-out;
  transform: scaleY(0);
  transform-origin: top; // keep the top of the element in the same place
  width: 100%;
  color: white;
  overflow: hidden;

  &.modal {
    position: initial;
    width: auto;

    div.title {
      margin-bottom: rem(8);
    }

    div.title,
    div.text {
      text-align: left;
    }
  }

  &.active {
    transform: scaleY(1); // implicit, but good to specify
  }
  &.hasHeader {
    top: $header-height;
  }
  &.hasHomepageHeader {
    top: rem(50);
    min-height: rem(80);
  }

  div.toastContent {
    max-width: 80%;
    margin: rem(10) rem(20);
    &.homepageMargin {
      margin: rem(20) rem(20);
    }

    div.title {
      font-size: rem(16);
      line-height: rem(20);
      font-weight: bold;
    }
    div.text {
      font-size: rem(12);
      line-height: rem(16);
    }
  }
  &.confirm div.toastContent div.text {
    font-size: rem(16);
  }
}
