@use "assets/styles" as *;

div.other-tip-amount-container {
  p.tip-instructions {
    @include reg-body;
    font-weight: bold;
    text-align: left;
    margin: rem(32) 0 rem(16);
    padding-left: rem(4);
  }

  button {
    display: block;
    width: 100%;
    max-width: rem(500);
    margin: rem(10) auto 0;

    &.in-mobile-app {
      margin-bottom: rem(20);
    }
  }
}
