@use "assets/styles" as *;

div.loyalty-tier-container {
  background-color: $sheetz-red;
  display: flex !important;
  background-size: 100%;
  width: 100%;
  padding: rem(20);

  svg.tier-icon,
  svg.zsquad-icon {
    width: rem(65);
    path.zsquad_icon_1 {
      fill: $white;
    }
    path.zsquad_icon_2 {
      fill: $sheetz-red;
    }
  }

  > div {
    margin: auto;

    p {
      color: $white;
      font-size: rem(14);
      font-weight: bold;
      margin-bottom: rem(8);

      &.tier-expires-label,
      &.tier-maintain-label {
        font-size: rem(10);
        line-height: 125%;
      }
    }
  }

  div:last-child {
    flex: 1;
    padding-left: rem(12);
  }
}

div.loyalty-tier-swoop {
  height: rem(50);

  svg {
    height: rem(50);
    width: 100%;
  }
}

p.message {
  font-size: rem(12);
  color: $tertiary-gray;
  padding: rem(20) rem(20) 0;
  margin: 0 auto;
  max-width: rem(600);
}
ul.card-list {
  list-style-type: none;
  padding: rem(12) rem(20) rem(20);
  margin: 0 auto;
  max-width: rem(600);

  li.card-item {
    button {
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      border-bottom: $default-border;
      padding: rem(10);
      div {
        display: grid;
        grid-template-columns: rem(50) auto rem(60);
        margin: auto;
        div {
          display: block;
        }

        .card-icon-container svg {
          width: rem(32);
          path {
            fill: $primary-dark-gray;
          }
        }
        .card-label-container {
          margin: auto rem(12);
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            line-height: rem(16);
            margin: rem(8) auto rem(4);
            &.card-name {
              font-weight: bold;
              font-size: rem(16);
              color: $primary-dark-gray;
            }
            &.card-number {
              color: $tertiary-gray;
            }
          }
        }
        .card-attribute-container {
          font-size: rem(12);
          color: $tertiary-gray;
          margin-left: rem(-40);
          margin-top: rem(35);

          .refresh-icon {
            width: rem(32);
            path {
              fill: $primary-dark-gray;
            }
          }
        }
      }
    }
    &.placeholder button div {
      .card-icon-container {
        background-color: $border;
        width: rem(50);
        height: rem(50);
        margin: auto;
        border-radius: rem(10);
      }
      .card-label-container p {
        height: rem(16);
        background-color: $border;
        border-radius: rem(4);
      }
    }
  }
  li.add-card-row {
    width: 100%;
    text-align: center;
    button.add-mysheetz-button {
      margin: rem(30) auto 0;
      width: 90%;
      max-width: rem(400);
    }
  }
}
