@use "assets/styles" as *;

div.pickup-info-container {
  padding-bottom: rem(20);

  button.disabled {
    opacity: 1;
  }

  div.list-item-container .selected-store {
    padding: 0 rem(14) 0;
  }

  p {
    text-align: left;
    font-size: rem(12);
  }

  div.pickup-info-list-item {
    display: flex;
    align-items: center;

    div.pickup-info-list-icon-container {
      width: rem(80);
      svg {
        height: rem(40);

        &.order-location-icon {
          path {
            fill: $sheetz-red;
          }
        }

        &.pickup-location-icon,
        &.pickup-time-icon {
          path {
            fill: $primary-dark-gray;
          }
        }
      }
    }

    div.pickup-info-list-item-label {
      font-weight: bold;
    }
  }

  div.pickup-info-button-container {
    padding: rem(40) rem(20) 0;
    button {
      margin: rem(5) 0;
      width: 100%;
    }
  }
}
