@use "assets/styles" as *;

.print-voucher-homepage {
  position: relative;
  z-index: 1;

  div.sheetz-header {
    display: flex;
    position: relative;
    height: rem(115);
  }

  div.voucher-details {
    display: block;
    margin: auto;

    @include desktop {
      width: rem(731);
    }

    div.card-barcode {
       display: flex;
       justify-content: center;
     }

    div.card-container {
      margin-top: rem(16);
      padding: rem(24) 0 rem(10);
      border-top: $default-border;
      border-bottom: $default-border;

      div.card-title {
        @include reg-body;
        padding: 0 rem(20);
      }

      div.card-details {
        @include xl-body;
        padding: 0 rem(20) rem(12);
      }
    }

    .voucher-icon img,
    .voucher-icon svg {
      width: rem(374);
      padding: rem(20) rem(20) rem(14);

      @include desktop {
        display: flex;
        margin: auto;
      }
    }

    .button-row {
      display: flex;
      height: rem(48);
      margin: rem(30) rem(20) rem(20);

      button {
        flex-grow: 1;
      }
    }
  }
}

.error-page-description {
  @include reg-body;
  padding-top: rem(20);
}

.button-row {
  height: rem(48);
  margin: rem(20);

  button {
    flex-grow: 1;
  }
}
