@use "assets/styles" as *;

div.confirmation-action-sheet-message {
  margin-top: rem(32);
  font-weight: bold;
  font-size: rem(18);
  text-align: left;
  padding: 0 rem(16);
  line-height: 150%;
  white-space: pre-line;
}

div.confirmation-action-sheet-flex-container {
  display: flex;
  padding-top: rem(64);
  align-items: center;

  > * {
    flex: 1 1 50%;
  }
}
