@use "assets/styles/variables" as *;
@use "assets/styles/colors" as *;

@mixin mobile {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin order-confirmation-desktop {
  @media (min-width: #{$order-confirmation-desktop-width-rem}) {
    @content;
  }
}

@mixin desktop-modal {
  @media (min-width: #{$desktop-width}) and (min-height: #{$modal-desktop-height}) {
    @content;
  }
}

@mixin desktop-max-width {
  @media (min-width: #{$desktop-width}) {
    max-width: $desktop-width-rem;
    margin: 0 auto;
    @content;
  }
}

@mixin order-config-sidebar-padding {
  @media (min-width: #{$desktop-width}) {
    padding-left: $order-config-sidebar-padding;
  }
}

@mixin unstyled-button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: $white;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: 1px solid $white;
    outline-offset: 2px;
  }

  &:active {
    transform: scale(0.99);
  }
}

@mixin obviously {
  font-family: "obviously", sans-serif;
  text-transform: capitalize;
}

@mixin obviously-narrow {
  font-family: "obviously-narrow", sans-serif;
  text-transform: uppercase;
}

@mixin roboto {
  font-family: "Roboto", sans-serif;
}

@mixin bottom-border {
  border-bottom: 1px solid $border;
}
