@use "assets/styles" as *;

div.receipt-additional-order-info {
  padding: 0 rem(10);
  color: $primary-dark-gray;

  p.heading {
    @include xl-body;
    margin-bottom: rem(18);
  }

  > div {
    margin-bottom: rem(18);

    span.label {
      @include lg-body;
      color: $secondary-medium-gray;
    }

    span.value {
      @include lg-body;
      color: $primary-dark-gray;

      &.date-time {
        text-transform: lowercase;
      }
    }
  }

  > div:last-child {
    margin-bottom: 0;
  }
}
