@use "assets/styles" as *;

$minimum-spacing: rem(12);

div.pickup-time-container {
  padding: 0 rem(30);
  margin: auto 0;
  // This gives a bit of spacing between the order config component and this container.
  padding-top: $minimum-spacing;
  // This pushes the container upwards without adding the possibility of a collision.
  padding-bottom: calc(#{$header-height} + #{$order-config-height} + #{$minimum-spacing});

  &.pickup-time-present {
    padding-bottom: calc(
      #{$header-height} + #{$order-config-height-with-pickup-time} + #{$minimum-spacing}
    );
  }

  @include order-config-sidebar-padding;

  div.pickup-time-details {
    p.heading {
      color: $secondary-medium-gray;
    }

    ul {
      margin: rem(20) 0 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
