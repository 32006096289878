@use "assets/styles" as *;

div.store-information {
  p {
    color: $white;
    @include sm-body;
    line-height: rem(16);
    text-align: left;

    &.delivery-pickup-title {
      max-width: rem(150);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media all and (min-width: 650px) {
        max-width: rem(200);
      }
    }
  }
}