@use "assets/styles" as *;

.portioning-selector-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(16);

  > * {
    height: rem(180);
  }
}
