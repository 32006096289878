@use "assets/styles" as *;

div.alcohol-delivery-actionsheet {
  text-align: left;

  p.alcohol-items-list {
    margin-top: rem(32);
    @include lg-body;
  }

  div.alcohol-delivery-buttons {
    margin-top: rem(32);
    height: rem(48);
    display: flex;
    align-items: center;
    button {
      flex-grow: 1;
    }
  }
}
