@use "assets/styles" as *;

div.barcode {
  background-color: $white;
  border-radius: rem(20);
  padding: rem(20) rem(20) rem(16);
  text-align: center;

  p.instructions {
    @include lg-body;
    color: $primary-dark-gray;
    margin-top: rem(8);
  }
}
