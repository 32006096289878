@use "assets/styles" as *;

div.reorder-detail-list-item {
  border-bottom: solid 1px $border;
  padding: rem(20);

  div.unavailable {
    opacity: 0.5;
  }

  div.item-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  div.item-discount {
    margin-top: rem(12);
  }

  div.reorder-detail-list-item-quantity {
    margin-top: rem(12);
  }

  button.remove-item-button {
    display: block;
    width: 90%;
    margin: rem(16) auto 0;
  }
}
